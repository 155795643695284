@import '../../../assets/colors';

.log-out-modal-title {
  margin-bottom: 40px;
}

.confirm-log-out-text {
  color: $light-gray;
  margin-bottom: 20px;
  font-size: 1.143rem;
}

.buttons-wrapper {
  @media (min-width: 768px) {
    justify-content: center;
    display: flex;
  }

  .log-out-modal-btn {
    width: 100%;
    padding: 10px 20px;

    @media (min-width: 768px) {
      width: unset;
      padding: 10px 40px;
    }

    &:first-child {
      margin-bottom: 10px;

      @media (min-width: 768px) {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
  }
}
