@import '../../../media';

.breadcrumbs {
  margin-bottom: 40px;
  color: #000;

  @include _768 {
    margin-bottom: 37px;
  }

  .breadcrumbs-sep {
    margin: 0 10px;
  }
}
