@import '../../assets/_colors';

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  align-self: start;
  color: $green-dark;
  cursor: pointer;
  * {
    line-height: 1.5 !important;
  }

  &--sm * {
    font-size: 0.8571rem !important;
  }
}

.checkbox__btn {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox__custom {
  position: relative;
  margin-right: 10px;
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
  border: 1px solid $gray-form;
  border-radius: 5px;
  line-height: 1;
  transition: border-color 0.2s;

  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    content: '\2714';
    color: transparent;
    transition: color 0.2s;
  }
}

.checkbox__btn:hover + .checkbox__custom,
.checkbox__btn:not(:disabled):active + .checkbox__custom,
.checkbox__btn:not(:disabled):focus + .checkbox__custom,
.checkbox__btn:focus:not(:checked) + .checkbox__custom {
  border-color: $green-hover;
}

.checkbox__btn:checked + .checkbox__custom:after {
  color: $green-primary;
}
