@import '../../media';
@import '../../assets/_colors';

.orders__section {
  position: relative;
  margin: 0 auto;
  max-width: 615px;
}

.orders__list {
  padding: 35px 20px 20px;
  margin-bottom: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  @media (min-width: 768px) {
    padding: 40px;
  }
}

.orders__title {
  margin-bottom: 27px;
  font-size: 1.714rem !important;

  @media (min-width: 768px) {
    font-size: 2.571rem !important;
  }

  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
}

.orders__telegram-info {
  color: $medium-gray;
}

.divider {
  width: 100%;
  border-bottom: 1px dashed #cedfde;
  transform: scaleX(3.5);
}

.divider-container {
  width: 100%;
  overflow: hidden;
}

.orders__dish-list-divider {
  margin-bottom: 30px;
}

.orders__dish-item-divider {
  margin: 20px 0;
}

.order {
  .link {
    background-color: unset !important;
  }
}

.order__heading {
  margin-bottom: 30px;
  position: relative;

  @media (max-width: 767px) {
    margin-bottom: 75px;
  }

  &_number-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    @media (max-width: 767px) {
      row-gap: 0;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
    position: relative;
  }
}

.order__number {
  margin-bottom: 10px;
  font-size: 1.286rem;
  color: #3d5b5a;
  font-weight: 700;

  @media (min-width: 768px) {
    margin-bottom: 0;
    font-size: 1.714rem;
  }
}

.order__date {
  font-size: 14px;
  color: #709594;

  @media (min-width: 768px) {
    padding-top: 10px;
  }

  @media (max-width: 767px) {
    padding-top: 4px;
  }
}

.order__content {
  margin-bottom: 30px;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}

.order__content-item {
  margin-bottom: 30px;

  @media (min-width: 768px) {
    width: 50%;
    padding-right: 50px;
    margin-bottom: 40px;

    &_status {
      width: auto;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--big {
    width: 100%;
  }
}

.order__content-item-label {
  margin-bottom: 13px;
  font-size: 0.8571rem;
  color: #709594;
}

.order__content-item-value {
  position: relative;
  font-size: 14px;
  color: #3d5b5a;

  &_status {
    font-weight: 500;
    font-size: 15px;

    @media (max-width: 767px) {
      position: absolute;
      top: 80px;
    }
  }

  &--status-icon {
    padding-left: 23px;
  }
}

.order-comment {
  word-break: break-word;
  overflow: hidden;
}

.order__dish {
  display: flex;
}

.order__dish-list-wrapper {
  overflow: hidden;
}

.order__dish-list {
  padding-bottom: 20px;
}

.order__dish-img {
  display: block;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 15px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.order__dish-info {
  width: 100%;

  @media (min-width: 768px) {
    padding-top: 8px;
  }
}

.order__dish-info-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    margin-bottom: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(2) {
    margin-top: 20px;
  }
}

.order__dish-title {
  max-width: 250px;
  display: inline-block;
}

.order__dish-amount-price-wrapper {
  padding-left: 20px;
  margin-left: auto;
}

.order__dish-amount {
  font-size: 0.8571rem;
  color: #709594;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

.order__dish-price {
  display: inline-block;
  min-width: 60px;
  text-align: right;
  font-size: 1rem;
  color: #3d5b5a;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 1.143rem;
  }
}

.order__checklist {
  max-width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.checklist__item {
  position: relative;
  padding-left: 30px;
  color: #709594;
  margin-bottom: 15px;
  font-size: 0.8571rem;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    height: 12px;
    display: block;
    content: '';
    position: absolute;
    left: 6px;
    top: -1px;
    width: 6px;
    height: 14px;
    border: solid #a7c205;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.order__dish-ingredient {
  max-width: 250px;
  position: relative;
  padding-left: 30px;
  font-size: 0.8571rem;
  color: #709594;

  &::before {
    display: block;
    content: '+';
    position: absolute;
    top: -5px;
    left: 2px;
    font-size: 20px;
    color: #a7c205;
  }
}

.order__total-price-wrapper {
  overflow: hidden;
  flex-direction: column;
  row-gap: 20px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.order__price {
  margin-bottom: 20px;
  width: 100%;

  &_total {
    margin-top: 15px;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.total-price__title {
  display: inline-block;
  margin-right: 10px;
  font-size: 1.143rem;
  font-weight: 700;
  color: #3d5b5a;
}

.total-price__value {
  display: inline-block;
  font-size: 1.714rem;
  line-height: 1;
  font-weight: 700;
  color: #3d5b5a;

  .rub-sign {
    font-size: 1.714rem;
  }
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'GothamPro', Verdana, sans-serif;
  background-color: #a7c205;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 18px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: 0.2s all;
}

.order__btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    display: flex;
    flex-flow: column-reverse;
  }

  &--without-survey {
    justify-content: flex-end;
  }
}

.order__btn-survey {
  margin-bottom: 0;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
}

.order__points {
  margin-bottom: 10px;
  width: 100%;
}

.order__points-title {
  display: inline-block;
  margin-right: 5px;
  font-size: 1.143rem;
  color: #3d5b5a;
}

.order__points-value {
  display: inline-block;
  font-size: 1.143rem;
  line-height: 1;
  color: #3d5b5a;
}

.icon-star {
  margin-right: 5px;
  display: inline;

  &::before {
    content: '\e921';
    color: #a7c205;
  }
}

.icon-status {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: 17px;
}

.icon-status__error {
  background-image: url('../../assets/icons/orders/error.svg');
  margin-top: -3px;
}

.icon-status__success {
  background-image: url('../../assets/icons/orders/success.svg');
  margin-top: 0;
}

.icon-status__cooking {
  background-image: url('../../assets/icons/orders/cooking.svg');
  margin-top: 0;
}

.icon-status__way {
  background-image: url('../../assets/icons/orders/way.svg');
  margin-top: -1px;
}

.order__btn-repeat-order {
  width: 100%;
  padding: 15px 20px;
  border-radius: 23px;

  @media (min-width: 768px) {
    width: unset;
    padding: 15px 50px;
  }
}

.empty-box {
  text-align: center;
  padding: 80px 25px 125px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  .icon-empty2 {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 85px;

    &::before {
      color: #e5eeee;
      content: '\e913';
    }
  }
}

.empty-box__icon {
  width: 85px;
  height: 85px;
  margin: 0 auto 35px;
  font-size: 85px;
}

.empty-box__text {
  text-align: center;
  font-size: 1rem;
  line-height: 1.65;
  color: #92b3b2;

  @media (min-width: 768px) {
    font-size: 1.286rem;
  }
}

.infinite-scroll-component {
  overflow: visible !important;
}
