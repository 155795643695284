@import '../../../../assets/colors';
@import '../../../../media';

.product-composition {
  display: flex;
}

.product-composition__item {
  margin-right: 20px;

  @include _992 {
    margin-right: 10px;
  }

  @include _1350 {
    margin-right: 20px;
  }

  &:last-child {
    margin-right: 0;
  }

  .product-composition__title {
    margin-bottom: 2px;
    font-size: 0.6429rem;
    color: $light-gray;
  }

  .product-composition__value {
    font-size: 0.8568rem;
    font-weight: 500;
    color: $green-primary;
  }
}
