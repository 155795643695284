@import '../../media';
@import '../../assets/_colors';

h3,
h1 {
  margin: 0;
  padding: 0;
}

.privacyPolicy-section {
  max-width: 750px;
  margin: 0 auto;

  .privacyPolicy-title__wrapper {
    margin-bottom: 20px;
    margin-right: 0;

    .privacyPolicy__info {
      color: $green-dark;
    }
  }

  .privacyPolicy-version {
    text-transform: none;
  }

  .privacyPolicy-content__wrapper {
    padding: 40px 20px 50px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    @include _768 {
      padding: 50px 45px;
    }

    .privacyPolicy-heading {
      margin-bottom: 35px;
      font-size: 1.143rem !important;
      color: $green-dark;
      font-weight: 700;
    }

    .privacy-policy__p {
      margin-bottom: 30px;
      line-height: 1.55 !important;
      color: $green-dark;

      &:last-child {
        margin-bottom: 0;
      }

      .privacy-policy__text-bold {
        font-weight: 700;
      }
    }

    .privacy-policy__link {
      margin-bottom: 5px;
    }
  }
}
