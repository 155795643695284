@import '../../../assets/colors';
@import '../../../media';

@mixin error-text {
  letter-spacing: initial;
  font-size: 0.84rem !important;
  color: #ec704b;
}

.log-in-modal-title {
  margin-top: 0;
  margin-bottom: 55px;

  @media (min-width: 768px) {
    margin-bottom: 80px;

    &.keep-margin {
      margin-bottom: 55px;
    }
  }
}

.login-form__text {
  font-size: 14px;
  color: #3d5b5a;
  margin-bottom: 20px;
}

.log-in-resend-wrapper {
  margin-bottom: 10px;
}

.log-in-modal-btn {
  min-width: 205px;
  padding: 10px 20px;
  margin-left: -10px;
}

.modal-text {
  margin-bottom: 30px;
  display: block;
}

.icon-back:before {
  color: $green-primary;
  font-size: 9px !important;
  margin-right: 4px;
  content: '\e905';
  margin-top: 2px;
}

.log-in-timer {
  color: $light-gray;
  margin: 0 auto 30px;
  width: fit-content;
  padding-right: 14px;
}

.back-button {
  position: absolute;
  padding: 0;
  top: 25px;
  left: 0;
}

.phone-input-active {
  margin-bottom: 35px;
}

.input-container {
  height: 47px;

  .MuiTextField-root {
    .MuiInput-root {
      display: flex;
      justify-content: center;
      border-bottom: $green-primary 1px solid;

      .centered-input {
        text-align: center;
        margin-bottom: 10px;
        padding: 0;

        &::placeholder {
          font-size: 0.8571rem;
          color: $light-gray;
        }
      }

      .masked-input {
        border: none !important;
        outline: none !important;
        color: $green-dark;

        &:disabled {
          background-color: transparent;
        }
      }

      &:before,
      &:after {
        content: none !important;
      }

      &.Mui-error {
        border-bottom-color: #f69071;

        &:after {
          content: none !important;
        }
      }

      &.Mui-disabled {
        border-bottom-color: #cedfde;
      }
    }

    .MuiFormHelperText-root {
      @include error-text;
      padding-top: 5px;
      text-align: right;
      margin-top: 0;
      letter-spacing: -0.05px;
    }
  }
}

.accept-terms {
  margin-bottom: 75px;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

.login-form__text + .accept-terms {
  margin-bottom: 20px;
}

.simple-error {
  margin-top: -30px;
  text-align: right;

  @include error-text;
}

.successful-auth {
  display: block;
  color: $green-primary;
  padding: 0 0 28px 0;
}
