@import '../../assets/colors';
@import '../../media';

footer {
  width: 100%;
  background-color: #203131;
  padding: 25px 0;
  height: 83.6px;
  order: 2;

  @media (max-width: 991.5px) {
    height: 236.4px;
  }

  @include _992 {
    overflow-x: hidden;
  }

  @include _1600 {
    padding-left: 110px;
  }

  [class*='footer-text'] {
    font-size: 0.8571rem !important;
    text-align: center;
    line-height: 1.3 !important;
    color: #808080;

    @include _1200 {
      display: block;
    }
  }

  .footer-content {
    height: 100%;

    @include _992 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }

  .footer-logo {
    display: none;

    @include _992 {
      display: flex;
      align-items: flex-end;
    }

    img {
      width: 100px;

      @include _1350 {
        width: 122px;
      }

      @include _1900 {
        width: 125px;
      }
    }
  }

  .footer-text-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &.green {
      color: $green-primary !important;
    }

    @include _1200 {
      line-height: 1.2 !important;
    }
  }

  .footer-img-link {
    &:first-child {
      margin-right: 20px;
    }

    img {
      width: 110px;
      height: 33px;
      display: block;
    }
  }

  .footer-icon-link {
    opacity: 0.4;
    * {
      font-size: 17px !important;
    }
    margin-right: 25px;
    transition: opacity 0.3s;
    text-decoration: none;

    @media (max-width: 991px) {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .icon-vk:before {
    content: '\e927';
    color: #fff;
  }

  .icon-instagram:before {
    content: '\e926';
    color: #fff;
  }

  .icon-telegram:before {
    content: '\e924';
    color: #61a8de;
  }

  [class*='container'] {
    margin-bottom: 19px;
    text-align: center;
    flex: 1;

    @include _992 {
      margin-bottom: 0;
    }
  }

  .footer-social-container {
    justify-content: center;
    margin-bottom: 23px;

    @include _992 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
      margin-right: 20px;
      margin-bottom: 0;
    }

    @include _1200 {
      flex-grow: 0;
      padding-bottom: 3px;
    }

    @include _1900 {
      margin-right: 17px;
    }
  }

  @include _992 {
    .footer-provided-by-container {
      margin: 0 20px;

      @include _1350 {
        min-width: 185px;
        flex-grow: 0;
      }

      @include _1900 {
        display: flex;
        flex-flow: row wrap;
        min-width: 195px;
        margin-right: 15px;

        span {
          margin-right: 4px;
        }
      }

      @include _2270 {
        margin-right: 20px;
      }
    }

    .footer-copyright-container {
      min-width: 160px;
      max-height: 31px !important;
      margin-bottom: 20px;
      text-align: center;
      line-height: 1.3;
      font-size: 0.8571rem;
      color: #808080;

      @include _992 {
        order: 4;
        margin-bottom: 0;
      }

      @include _1200 {
        white-space: nowrap;
        min-width: 190px;
      }

      @include _1900 {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        max-width: 250px;
      }
    }

    .footer-agreement {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      flex: 1;
    }
  }

  .footer-agreement {
    text-align: center;

    @include _768 {
      padding: 0 20px;
    }

    @include _1200 {
      align-self: flex-end;

      .footer-text-link {
        line-height: 1.43 !important;
      }
    }

    @include _1350 {
      min-width: 230px;
      flex-grow: 0;
    }

    @include _1600 {
      align-self: flex-end;
    }

    @include _1900 {
      min-width: 250px;
      align-self: center;
      margin-right: auto;
      margin-left: 0;
      padding-left: 0;
    }

    @include _2270 {
      padding-left: 11px;
    }
  }

  .footer-mobile-apps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 25px;
    flex: 1;

    @include _992 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
      padding: 0 10px;
      margin-bottom: 0;
    }

    @include _1200 {
      padding: 0 10px;
    }

    @include _1900 {
      flex-grow: 0;
    }

    @include _2270 {
      padding: 0 5px;
    }
  }
}
