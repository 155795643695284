@import './src/media';
@import './src/assets/colors';

.delivery-terms__title {
  margin-bottom: 25px;

  @include _768 {
    margin-bottom: 35px;
  }
}

.delivery-terms__content {
  padding: 50px 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @include _768 {
    padding: 50px 40px;
  }

  @include _1200 {
    padding: 65px 60px;
  }
}

.delivery-terms__text {
  max-width: 100%;
  margin-bottom: 35px;
  div {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.7;
    color: $green-dark;
  }

  @include _768 {
    font-size: 1.143rem;
    margin-bottom: 55px;
  }

  .delivery-terms__text-title {
    font-size: 1.143rem;
    font-weight: 600;
    color: $green-dark;
  }
}

@include _992 {
  .delivery-terms__areas {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
  }
}

.delivery-terms__areas-title {
  margin-bottom: 20px;
  font-size: 1.143rem;
  font-weight: 700;
  color: $green-dark;

  @include _992 {
    display: none;
  }
}

.delivery-terms__areas-desc {
  margin-bottom: 55px;

  @include _992 {
    min-width: 340px;
    margin-bottom: 0;
    margin-right: 35px;
  }
}

.delivery-terms__areas-desc .delivery-terms__areas-title {
  display: none;

  @include _992 {
    display: block;
  }
}

.delivery-terms__areas-map {
  height: 240px;
  background-color: $super-light-gray;
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: 25px;

  @include _768 {
    margin-right: -40px;
    margin-left: -40px;
  }

  @include _992 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    max-width: 640px;
    width: 100%;
    height: unset;
    margin: 0;
  }

  .delivery-areas__map {
    height: 240px;
    margin-bottom: 0;

    @include _992 {
      width: 400px;
      height: 100%;
    }

    @include _1200 {
      width: 550px;
    }

    @include _1350 {
      width: 640px;
    }
  }
}

.delivery-terms__areas-list {
  list-style: none;
  margin: 0 0 25px;
  padding: 0;
}

.delivery-terms__areas-list-item {
  margin-bottom: 18px;
  font-size: 14px;
  color: $green-dark;

  &:last-child {
    margin-bottom: 0;
  }
}

.delivery-terms__button {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 0.7143rem !important;
  color: $white !important;

  &:hover {
    text-decoration: none !important;
  }

  @include _768 {
    width: unset;
    padding: 10px 20px;
    font-size: 0.8571rem !important;
  }
}

@include _768 {
  .delivery-terms__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.delivery-terms__info-item {
  margin-bottom: 47px;

  @include _768 {
    width: 50%;
  }

  @include _992 {
    width: 33.33%;
    margin-bottom: 0;
  }

  @include _1200 {
    padding-left: 38px;

    &:first-child {
      padding-left: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.delivery-terms__info-item-icon {
  margin-bottom: 31px;

  span {
    justify-content: start !important;

    &:before {
      color: $green-primary;
      font-size: 25px !important;
    }
  }
}

.delivery-terms__info-item-title {
  margin-bottom: 25px;
  font-size: 1.143rem;
  font-weight: 700;
  color: $green-dark;
}

.delivery-terms__info-item-text {
  margin-bottom: 15px;
  font-size: 14px;
  color: $green-dark;

  &:last-child {
    margin-bottom: 0;
  }
}

.delivery-term-text-link {
  &:hover {
    text-decoration: none;
  }

  .green {
    color: $green-primary !important;
  }
}

.icon-file:before {
  content: '\e932';
}

.icon-wallet:before {
  content: '\e933';
}

.icon-clock:before {
  content: '\e90b';
  color: #aec719;
}
