@import '../../media';
@import '../../assets/_colors';

.place-order__previous-comments,
.place-order__previous-addresses {
  position: absolute;
  right: -10px;
  width: 40px;
  height: 35px;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
  bottom: unset;
  top: -6px;
  z-index: 2;
}

.place-order__last-comments-icon {
  display: inline-block;
  height: 18px;
  width: 19px;
  background-image: url('../../assets/icons/orders/recent_comments.svg');
}

.place-order__last-addresses-icon {
  display: inline-block;
  height: 18px;
  width: 19px;
  background-image: url('../../assets/icons/orders/recent_adress.svg');
}

.tooltip {
  left: unset;
  right: -10px;
  position: absolute;
  z-index: 100;
  top: calc(100% + 10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.tooltip__inner {
  position: relative;
  width: 250px;
  padding: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @include _768 {
    width: 300px;
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: -4px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    left: unset;
    right: 25px;
  }
}

.tooltip__text {
  margin-bottom: 10px;
  font-size: 0.8571rem;
  line-height: 1.5;
  color: $green-dark;

  &:last-child {
    margin-bottom: 0;
  }
}

.uppercase {
  text-transform: uppercase;
}
