@import '../../assets/colors';
@import '../../media';

.cart-section {
  max-width: 1095px;
  margin: 0 auto;

  .page-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    @include _768 {
      margin-bottom: 35px;
    }

    .cart-heading {
      font-size: 1.714rem !important;

      @include _768 {
        font-size: 2.571rem !important;
      }
    }
  }
}
