@import '../../../media';
@import '../../../assets/colors';

.share {
  position: relative;

  .share__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    background-color: #fff;
    box-shadow: 0 4px 10px #e0e0e0;
    cursor: pointer;
    z-index: 5;

    .icon-share {
      &:before {
        content: '\e91d';
        color: $green-dark;
        font-size: 20px;
      }
    }
  }
}

.share__content-wrapper {
  position: absolute;
  top: calc(100% + 10px);
  right: -10px;
  display: block;
  z-index: 7;

  @include _768 {
    top: calc(100% + 5px);
    right: -10px;
  }

  @include _992 {
    top: 0;
    right: calc(100% + 10px);
  }

  .share__content {
    position: relative;
    width: 300px;
    padding: 15px 5px 15px 15px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 4px 10px #e0e0e0;

    @include _992 {
      width: 375px;
      padding: 15px;
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: -4px;
      right: 25px;
      width: 10px;
      height: 10px;
      background-color: #fff;
      transform: rotate(45deg);

      @include _992 {
        top: 15px;
        right: -4px;
      }
    }

    .share__copy {
      display: flex;
      margin-bottom: 15px;

      .share__copy-text {
        overflow: hidden;
        padding: 13px 15px 10px;
        border-radius: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1rem;
        color: $green-dark;
        background-color: #e7efee;
      }

      .share__copy-button {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        padding: 0;
        border: none;
        font-size: 20px;
        background-color: transparent;
        cursor: pointer;

        .icon-copy-file {
          &:before {
            font-size: 20px;
            content: '\e92e';
            color: #a7c205;
          }
        }
      }
    }

    .share__info {
      font-size: 0.8571rem;
      line-height: 1.5;
      color: $light-gray;

      @include _992 {
        padding-right: 40px;
      }
    }
  }
}
