@import '../assets/colors';
@import '../media';

.modal-window-body {
  position: relative;
  margin: auto;
  padding: 50px 30px 60px;
  width: 280px;
  outline: none;
  border: none;
  text-align: center;
  color: $green-dark;
  background-color: $white;
  background:
    linear-gradient(-45deg, transparent 27px, $white 0) right,
    linear-gradient(135deg, transparent 27px, $white 0) left;
  background-size: 55% 100%;
  background-repeat: no-repeat;

  @include _768 {
    padding: 50px 55px 60px;
    width: 450px;
    background:
      linear-gradient(-45deg, transparent 49px, $white 0) right,
      linear-gradient(135deg, transparent 49px, $white 0) left;
    background-size: 55% 100%;
    background-repeat: no-repeat;
  }
  .message-modal-additional-message {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 35px;
  }

  [class*='modal-title'] {
    font-size: 1.714rem !important;
    font-weight: 700;
    text-transform: uppercase;
  }

  .close-modal-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    background-color: $green-primary;
    cursor: pointer;

    * {
      color: $white;
      font-size: 17px !important;
    }

    .icon-close-modal:before {
      content: '\e90d';
    }
  }

  .spinner-wrapper {
    min-height: 294px;

    @include _768 {
      min-height: 317px;
    }
  }
}

.presence-info-modal {
  display: flex;
  width: 100%;
  padding: 50px 55px 20px;
  background:
    linear-gradient(-45deg, transparent 0, #fff 0) right,
    linear-gradient(135deg, transparent 49px, #fff 0) left;
  background-size: 55% 100%;
  background-repeat: no-repeat;
  //height: 850px;

  @include _576 {
    width: 450px;
    margin-top: 35px;
    //height: 830px;
  }

  @include _768 {
    width: 550px;
  }
}
