@import './src/media';

.geo-button {
  position: absolute;
  bottom: 62px;
  right: -10px;
  width: 40px;
  height: 35px;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;

  @include _768 {
    bottom: 0;
  }

  & > * {
    font-size: 18px !important;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}
