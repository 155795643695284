@import '../../../assets/colors';
@import '../../../media';

.modal__title {
  font-size: 1.714rem;
  color: $green-dark;
  margin-bottom: 40px !important;
  font-weight: 700;
  text-transform: uppercase;
}

.modal-action-form__text {
  font-size: 1.143rem;
  color: $light-gray;
  margin-bottom: 20px;
}

.buttons-wrapper {
  @include _768 {
    display: flex;
    justify-content: center;
  }
}

.buttons-wrapper__button {
  width: 100%;
  padding: 10px 40px;

  @include _768 {
    width: unset;
  }

  &:first-child {
    background-color: #e6eeee;
    color: $medium-gray;
    margin-bottom: 10px;

    @include _768 {
      margin-bottom: 0;
      margin-right: 10px;
    }

    &:hover {
      background-color: $green-primary;
      color: #fff;
    }
  }
}
