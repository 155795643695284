@import '../../../media';
@import '../../../assets/colors';

.product-card__tooltip-icon {
  margin-left: 10px;

  .tooltip-icons__img {
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.product-card__tooltip-icon_big {
  margin-left: 15px;
}

.tooltip-info__text {
  min-width: 320px;
  padding: 1rem 0 1rem 10px !important;
  border-radius: 5px;
  text-overflow: ellipsis;
  font-weight: normal !important;
  font-size: 1rem !important;
  color: #e7efee;
  white-space: pre-wrap !important;
  background-color: $green-dark !important;
}

.cart-item {
  .product-card__tooltip-icon {
    img {
      width: 18px;
      height: 18px;

      @include _992 {
        width: 22px;
        height: 22px;
      }
    }
  }
}
