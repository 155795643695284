@import '../../assets/colors';
@import '../../media';

.MuiFab-root {
  background-color: $green-primary !important;
  width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;

  @include _992 {
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
  }
}

.cart-content__wrapper {
  padding: 20px 15px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  .cart__wrapper {
    height: 100%;
    overflow-x: hidden;
  }

  @include _992 {
    padding: 35px;
  }

  .cart__items-list {
    padding: 10px 10px 20px;

    .cart-item {
      position: relative;

      @include _768 {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
      }
      .cart-item__img {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        cursor: pointer;

        @include _992 {
          width: 100px;
          height: 100px;
        }

        &--stop {
          display: flex;
          justify-content: center;
          align-items: center;

          &::before {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(143, 186, 184, 0.85);
            backdrop-filter: blur(3px);
            border-radius: 5px;
          }
        }
      }

      &__img-text {
        position: relative;
        z-index: 3;
        max-width: 80px;
        margin: 0 0 3px;
        text-align: center;
        font-family: 'Roboto', sans-serif !important;
        font-weight: 700;
        font-size: 13px;
        line-height: 1;
        color: #ffffff;

        @media (max-width: 992px) {
          font-size: 9px;
        }
      }

      .cart-item__title {
        min-height: 32px;
        padding: 0 40px 0 70px;
        margin-bottom: 10px;
        cursor: pointer;

        @include _768 {
          width: 50%;
          min-height: unset;
          padding-right: 0;
          margin-bottom: 15px;
        }

        @include _992 {
          min-height: 55px;
          padding-left: 140px;
          margin-bottom: 12px;
        }
      }

      .cart__item-close-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        top: -10px;
        right: -10px;
        width: 40px;
        height: 40px;
        border: none;
        padding: 0;
        background-color: transparent;
        cursor: pointer;

        @include _992 {
          top: -5px;
          width: 30px;
          height: 30px;
        }

        .cart__item-close {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $green-dark;
          transition: background-color 0.2s;

          @include _992 {
            width: 30px;
            height: 30px;
            background-color: transparent;

            &:hover {
              background-color: $green-dark;

              span:before {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.icon-close {
  &:before {
    content: '\e90d';
    color: #fff;
    font-size: 8px !important;

    @include _992 {
      color: $green-dark;
      font-size: 10px !important;
    }
  }
}

.cart__item-composition-weight {
  @include _768 {
    display: flex;
    order: 1;
    width: 100%;
    padding-left: 70px;
  }

  @include _992 {
    padding-left: 140px;
  }

  .cart__item-weight {
    padding: 0 40px 0 70px;
    margin-bottom: 20px;
    font-size: 0.8571rem;
    color: $medium-gray;

    @include _768 {
      display: flex;
      align-items: flex-end;
      order: 1;
      padding: 0;
      margin-bottom: 0;
      margin-left: auto;
      margin-right: 170px;
    }

    @include _992 {
      font-size: 1rem;
      margin-right: 190px;
    }

    @include _1200 {
      margin-right: 305px;
    }
  }
}

.cart__item-composition {
  margin-bottom: 15px;

  @include _768 {
    margin-bottom: 0;
  }

  .product-composition__item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    .product-composition__title {
      font-size: 0.7143rem !important;

      @include _992 {
        font-size: 0.8571rem !important;
      }
    }

    .product-composition__value {
      font-size: 0.9286rem !important;

      @include _992 {
        font-size: 1rem !important;
      }
    }
  }
}

.cart__item-amount-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include _768 {
    position: relative;
    width: 50%;
    justify-content: flex-end;
    align-items: flex-start;
    padding-right: 50px;
  }

  @include _1200 {
    padding-right: 100px;
  }

  .cart__item-switcher {
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;

    @include _768 {
      order: 1;
      left: 0;
      margin-left: 30px;
      height: auto;
    }

    @include _992 {
      margin-top: -5px;
      height: auto;
    }

    @include _1200 {
      margin-left: 90px;
      height: auto;
    }
  }

  .cart__item-price {
    font-size: 1.143rem;
    font-weight: 700;
    color: $green-dark;

    & span {
      font-size: 0.9286rem;
    }
  }
}

.friendCart__item-amount-price {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include _768 {
    position: relative;
    width: 50%;
    justify-content: flex-end;
    align-items: flex-start;
    padding-right: 185px;
  }

  @include _1200 {
    padding-right: 295px;
  }

  .cart__item-price {
    font-size: 1.143rem;
    font-weight: 700;
    color: $green-dark;

    .rub-sign {
      font-size: 0.9286rem;
    }
  }
}

.friendCart__item-quantity {
  position: absolute;
  left: 0;
  font-size: inherit;
  font-weight: 300;

  @include _768 {
    right: 10px;
    left: initial;
    font-size: 1.143rem;
  }
}

.cart__item-divider {
  width: 100%;
  border-bottom: 1px dashed #cedfde;
  transform: scaleX(3.5);
  margin: 10px 0 20px;

  @include _768 {
    order: 2;
    margin: 20px 0;
  }

  @include _992 {
    margin: 30px 0;
  }
}

.cart__calories {
  margin-bottom: 35px;

  .cart__calories-title {
    font-size: 0.8571rem;
    color: $medium-gray;
  }

  .cart__calories-value {
    margin-left: 7px;
    font-size: 1.186rem;
    font-weight: 700;
    color: $green-primary;
  }
}

.dishes-price {
  margin-bottom: 20px;
}

.delivery-price {
  margin-bottom: 20px;
}

.free-delivery {
  margin-bottom: 20px;

  &__title {
    display: inline-block;
    margin-right: 10px;
    font-size: 1.3rem;
    font-weight: 700;
    color: $green-primary;
  }

  &__value {
    display: inline-block;
    font-size: 1.714rem;
    line-height: 1;
    font-weight: 700;
    color: $green-primary;
  }
}

.price-button-wrapper {
  @include _768 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .price-button-wrapper__price {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @include _768 {
      margin-bottom: 0;
    }

    .price-button-wrapper__title {
      display: inline-block;
      margin-right: 10px;
      font-size: 1rem;
      font-weight: 700;
      color: $green-dark;
    }

    .price-button-wrapper__value {
      display: inline-block;
      font-size: 1.714rem;
      line-height: 1;
      font-weight: 700;
      color: $green-dark;
    }

    .points-button-wrapper__value {
      font-size: 1.6rem;
      line-height: 1;
      font-weight: 700;
      color: $green-dark;
    }
  }

  .price-button-wrapper__button {
    width: 100%;
    padding: 15px 20px;
    border-radius: 23px;
    font-size: 0.85rem;

    @include _768 {
      width: unset;
      padding: 15px 50px;
    }

    &--stop {
      background: #99b8b7;
    }
  }
}

.cart__item-checklist {
  padding: 0;
  list-style: none;
  margin: 15px 0 20px;

  @include _992 {
    margin: 25px 0 0;
  }

  .checklist__item {
    position: relative;
    padding-left: 30px;
    font-size: 1rem;
    color: $medium-gray;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 6px;
      top: -1px;
      width: 6px;
      height: 14px;
      border: solid $green-primary;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.cart__toppings {
  margin-right: -40px;
  padding: 20px 0 15px;

  @include _768 {
    width: 320px;
    margin-right: 0;
  }

  @include _992 {
    width: 440px;
  }

  @include _1200 {
    width: 520px;
  }

  @include _1350 {
    width: 570px;
  }

  .toppings__item {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 10px;
    font-size: 1rem;
    padding-left: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @include _768 {
      margin-bottom: 12px;
    }

    .toppings__item-name {
      max-width: 160px;
      padding-right: 10px;
      font-size: 0.7143rem;
      color: $medium-gray;

      @include _768 {
        max-width: 200px;
      }

      @include _992 {
        max-width: 300px;
        font-size: 1rem;
      }
    }

    .toppings__item-value {
      margin-top: -4px;
      white-space: nowrap;
      font-size: 1rem;

      .toppings__item-amount {
        margin-right: 5px;
        font-size: 0.7143rem;
        color: $medium-gray;

        @include _992 {
          font-size: 1rem;
        }
      }

      .toppings__item-price {
        font-size: 0.7143rem;
        font-weight: 700;
        color: $green-dark;

        @include _992 {
          font-size: 1rem;
        }

        .toppings__item-price span {
          font-size: 0.7143rem;

          @include _992 {
            font-size: 0.8571rem;
          }
        }
      }
    }

    &:before {
      display: block;
      content: '+';
      position: absolute;
      top: -5px;
      left: 2px;
      font-size: 20px;
      color: $green-primary;
    }
  }
}

.cart__item-composition .product-composition__value {
  font-size: 0.9286rem !important;

  @include _992 {
    font-size: 1rem !important;
  }
}

.cart__item-composition .product-composition__title {
  font-size: 0.7143rem !important;

  @include _992 {
    font-size: 0.8571rem !important;
  }
}
