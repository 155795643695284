@import '../../media';
@import '../../assets/_colors';

h3,
h1 {
  margin: 0;
  padding: 0;
}

.userAgreement-section {
  max-width: 750px;
  margin: 0 auto;

  .menuPage-heading {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .userAgreement-content__wrapper {
    padding: 40px 20px 50px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    @include _768 {
      padding: 50px 45px;
    }

    .userAgreement-heading {
      margin-bottom: 35px;
      font-size: 1.143rem !important;
      color: $green-dark;
      font-weight: 700;
    }

    .user-agreement__p {
      margin-bottom: 30px;
      line-height: 1.55 !important;
      color: $green-dark;

      &:last-child {
        margin-bottom: 0;
      }

      .user-agreement__text-bold {
        font-weight: 700;
      }

      .user-agreement__img {
        max-width: 90%;
        @include _768 {
          max-width: 70%;
        }
      }
    }

    .user-agreement__links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;

      .user-agreement__link {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
