.dish-page {
  &__icons-wrpr {
    position: absolute;
    right: 0;
    bottom: 100%;
  }

  &__icon {
    width: 35px;
    height: 35px;

    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
}
