.ymaps-2-1-78-gotoymaps__text {
  font: 12px Arial !important;
  line-height: 24px !important;
}

.ymaps-2-1-78-gototech {
  font:
    12px/14px Verdana,
    Arial,
    sans-serif !important;
}

.ymaps-2-1-78-copyright__text,
.ymaps-2-1-78-copyright__link {
  font:
    11px/14px Verdana,
    Arial,
    sans-serif !important;
}
