@import '../../assets/colors';
@import '../../media';

@mixin hide-below-992 {
  @media (max-width: 991.5px) {
    display: none;
  }
}

@mixin container-in-search-bar($cursorType) {
  justify-content: center;
  width: 40px;
  height: 35px;
  cursor: $cursorType;
  padding: 0 !important;

  span:before {
    color: $light-gray;
  }
}

@mixin center-abs-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin search-width($enlarge) {
  @media (min-width: 768px) {
    width: 120px + $enlarge;
  }

  @media (min-width: 992px) {
    width: 250px + $enlarge;
  }

  @media (min-width: 1200px) {
    width: 280px + $enlarge;
  }
}

.user-menu-popover {
  z-index: 1310 !important;

  .MuiListItem-button {
    color: $light-gray;
    padding: 7px 15px !important;
    font-size: 13px !important;

    &:hover {
      background-color: #f0f7f7 !important;
    }
  }
}

.MuiPaper {
  &-elevation1 {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
    -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
  }
  &-elevation2 {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2) !important;
  }
}

.MuiAppBar-root {
  height: 70px;
  flex-direction: row !important;

  @media (min-width: 1600px) {
    padding-left: 110px !important;
  }
}

.MuiAppBar-colorPrimary {
  background-color: white !important;

  [class^='icon-'],
  [class*=' icon-'] {
    color: $green-primary;
  }
}

.search-suggestions-container {
  @include search-width(30px);
  z-index: 1301 !important;

  @media (max-width: 767px) {
    width: calc(100%);
    max-width: 460px !important;
  }

  .search-suggestions-paper {
    width: 100%;
    max-height: 256px;
    max-width: 460px !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);

    .MuiList-root {
      flex-direction: column;
      padding: 8px 0 0 0 !important;
    }

    .MuiListItem-root {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100% !important;
      line-height: 48px;
      height: 48px;
      padding: 0 16px !important;
      text-align: left;
      text-decoration: none;
      box-sizing: border-box;
      font-size: 13px !important;
      transition: background-color 0.3s;

      .suggestion-body {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #daebea;
        width: calc(100% - 30px);
        height: 100%;
        overflow-x: hidden;

        &.no-divider {
          border: none !important;
        }

        .suggestion-title {
          color: $green-dark;
          font-size: 1em !important;
          font-weight: 700;
          margin: 0 0 5px;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }

        .suggestion-info {
          color: $green-primary;
          font-size: 0.8571em !important;
          margin: 0;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.MuiToolbar-root {
  display: flex;
  flex-flow: column nowrap;

  .header-content {
    height: 100%;
    padding-left: 55px;
    width: 100%;

    @media (min-width: 576px) {
      position: relative;
      padding-left: 0;
    }

    .header-bike-icon {
      width: 26px;
      height: 25px;
      margin-right: 15px;
    }

    .header-content-left {
      justify-content: space-between;

      a {
        flex-shrink: 0;

        &:nth-child(2) {
          padding-left: 20px;
        }
      }

      @media (min-width: 768px) {
        width: unset;
      }

      @media (min-width: 992px) {
        width: 260px;
      }

      @media (min-width: 1200px) {
        width: 270px;
      }

      @media (min-width: 1250px) {
        width: 300px;
        margin-left: -30px;
      }

      @media (min-width: 1350px) {
        width: 410px;
        margin-left: -10px;
      }

      @media (min-width: 1750px) {
        width: 450px;
        margin-left: -50px;
      }

      .header-phone-number-wrapper {
        display: flex;
        flex-direction: column;
        @include hide-below-992;

        .phone-value {
          font-size: 0.8571em !important;
          color: $green-dark;

          span {
            font-size: 1rem;
            font-weight: 700;
          }
        }
      }

      .phone-icon-wrapper {
        height: 35px;
        display: inline-block;
        margin-right: 15px;

        .icon-phone:before {
          content: '\e916';
          font-size: 25px !important;
          padding-top: 6px;
        }
      }

      .header-opening-time-wrapper {
        display: flex;
        flex-direction: column;
        @include hide-below-992;

        .opening-time-days {
          font-weight: 500;
          text-transform: lowercase;
          color: $green-dark;

          .opening-time-hours {
            margin-left: 5px;
            font-size: 1rem;
            font-weight: normal;
            text-transform: none;
          }
        }
      }
    }

    .header-logo {
      flex-shrink: 0;
      display: block;
      width: 100px;

      img {
        display: block;
        width: 100%;
      }

      @include _768 {
        margin-left: 150px;
      }

      @include _992 {
        width: 115px;
        margin-left: 115px;
        margin-right: 10px;
      }

      @include _1200 {
        margin-left: 185px;
      }

      @include _1350 {
        margin-left: 130px;
      }
    }

    .header-content-right {
      position: relative;
      z-index: 20;
      margin-left: auto;
      padding-right: 10px;

      @include _768 {
        padding-right: 0;
      }

      &.full {
        @media (max-width: 767px) {
          width: 100%;
          margin-left: 0;
        }
      }

      [class^='icon-'],
      [class*=' icon-'] {
        font-size: 22px !important;

        @media (min-width: 768px) {
          font-size: 16px !important;
        }
      }

      .user-container {
        flex-shrink: 0;
        padding-right: 20px;
        cursor: pointer;
      }

      .icon-cart__usercart:before {
        content: '\e907';
        font-size: 16px !important;

        @media (max-width: 767px) {
          font-size: 22px !important;
        }
      }

      .user-popper-header {
        font-family: Arial, serif !important;
        color: $light-gray !important;
        font-size: 1rem !important;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 7px;
        padding: 0 1px;
        letter-spacing: normal;
        word-spacing: normal;
        line-height: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        text-align: center;
      }

      .icon-arrow__dropdown {
        &:before {
          margin-top: 1px;
          content: '\e902';
          color: #92b3b2;
          font-size: 9px !important;
        }
      }

      .icon-user:before {
        content: '\e925';
      }

      .icon-search:before {
        content: '\e91b';
        color: $light-gray;
        font-size: 20px !important;

        @media (min-width: 768px) {
          font-size: 18px !important;
        }
      }

      .icon-close-search:before {
        content: '\e90d';
        font-size: 0.7857rem !important;
        display: inline-block;
        height: fit-content;
      }

      .search-bar-container {
        background-color: #eef3f3;
        color: $green-dark !important;
        font-size: 1rem !important;
        padding: 0 40px !important;
        height: 36px;
        border: none;
        border-radius: 18px;
        position: relative;
        margin-right: 20px;

        @include search-width(0);

        &.full {
          @media (max-width: 767px) {
            width: 100%;
            max-width: 460px;
            margin: 0;
          }
        }

        &.is-authorized {
          margin-bottom: 0.8em;
          @include _576 {
            margin-bottom: 0;
          }
          @include _768 {
            position: absolute;
            right: 200px;

            @include search-width(100px);
          }

          @include _992 {
            @include search-width(20px);
          }

          @include _1200 {
            @include search-width(-35px);
          }

          @include _1350 {
            @include search-width(30px);
          }
        }

        .search-icon-wrapper {
          @include center-abs-vertical;
          left: 0;
          @include container-in-search-bar(default);
        }

        * {
          font-weight: 400;
        }
      }

      .search-button {
        margin-right: 28px;

        @include container-in-search-bar(pointer);
        opacity: 0.7;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;

        &:hover {
          opacity: 1;
        }

        @media (max-width: 767px) {
          font-size: 20px !important;
          opacity: 1;

          .icon-search:before {
            color: #a7c205;
          }
        }

        @include _768 {
          margin-right: 20px;
        }
      }

      .close-search-button {
        @include container-in-search-bar(pointer);
        @include center-abs-vertical;
        right: 0;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .header-title {
    font-size: 0.7857em !important;
    color: $light-gray;
    @include hide-below-992;
  }

  .header-free-delivery-title {
    font-size: 0.7857em !important;
    color: $green-primary;

    @include hide-below-992;

    @media (max-width: 1200px) {
      max-width: 140px;
      word-wrap: break-word;
    }
  }
}

.toggle-sidebar-button {
  background-color: $green-primary;
  height: 100%;
  border: none;
  width: 70px;
  min-width: 70px;
  cursor: pointer;
  font-size: 23px !important;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;

  @media screen and (max-width: 767px) {
    width: 55px;
    min-width: 55px;
  }

  @media (min-width: 1600px) {
    display: none;
  }

  span {
    color: #fff !important;
    font-size: 23px !important;

    &.icon-sidebar-toggle {
      @media (min-width: 1600px) {
        display: none;
      }

      &.open:before {
        content: '\e928';
      }

      &.close:before {
        content: '\e915';
      }
    }
  }
}

.header-cart__container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  .header__cart-amount {
    display: none;
    margin: 0 3px 0 7px;
    font-size: 0.8571rem !important;
    color: $light-gray;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .mobile__cart-amount {
    position: absolute;
    top: 4px;
    right: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: #fff;
    background-color: $green-primary;
    font-size: 9px;
    text-align: center;
    padding: 4px;
    box-sizing: border-box;
    line-height: 1;

    @media (min-width: 768px) {
      display: none;
    }
  }
}

.header__sidebar-title {
  display: none;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  height: 70px;
  width: 245px;
  padding: 0 26px;
  font-size: 1.286rem;
  font-weight: 500;
  color: #fff;
  background-color: $green-primary;

  @media (min-width: 1600px) {
    display: flex;
  }
}

.search-suggestions-container .search-suggestions-paper {
  @include _768 {
    width: 238px;
  }

  @include _992 {
    width: 250px;
  }

  @include _1200 {
    width: 280px;
  }
}

.search-suggestions-container-for-authorized .search-suggestions-paper {
  @include _768 {
    width: 220px;
  }

  @include _992 {
    width: 270px;
  }

  @include _1200 {
    width: 245px;
  }

  @include _1350 {
    width: 310px;
  }
}

.header-address {
  position: relative;
  width: 100%;

  &__choice__btn {
    position: absolute;
    left: 50px;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    z-index: 5;
    padding: 0 5px;
    max-width: 80%;
    color: $light-gray;
    cursor: pointer;

    @include _576 {
      left: 115px;
      bottom: 25px;
      width: 50%;
    }

    @include _768 {
      width: 21%;
    }

    @include _992 {
      left: 58%;
      bottom: 25px;
      width: 13%;
      &:hover {
        background: white;
        width: fit-content;
        position: absolute;
        z-index: 100;
      }
    }

    @include _1200 {
      left: 56%;
      width: 19%;
    }

    @include _1350 {
      width: 21%;
    }

    @include _1900 {
      left: 52%;
      width: 25%;
    }

    @include _2270 {
      left: 44%;
    }
  }

  &__desc {
    overflow: hidden;
    margin-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 1;
    text-align: left;
  }
}
