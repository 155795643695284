@import '../../media';
@import '../../assets/_colors';

.questionnaire-wrapper {
  @include _768 {
    max-width: 570px;
  }

  .hidden-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .questionnaire-heading {
    margin-right: 0;
    margin-bottom: 25px;
    font-size: 1.714rem !important;

    @include _768 {
      margin-bottom: 40px;
      font-size: 2.571rem !important;
      letter-spacing: 0;
    }
  }

  .questionnaire-paper {
    overflow: hidden;
    padding: 25px;

    @include _768 {
      padding: 50px;
    }

    .additional-questions,
    .questionnaire-item,
    .questionnaire-text-field {
      display: flex;
      flex-flow: column nowrap;
    }

    .questionnaire-item {
      .questionnaire-title {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 1.143rem;
        color: $green-dark;

        @include _768 {
          margin-bottom: 40px;
        }

        span {
          font: initial;
          font-weight: 700;
        }
      }

      .questionnaire-title_list {
        margin-bottom: 15px;

        @include _768 {
          margin-bottom: 25px;
        }
      }

      .text-field-input {
        position: relative;
        padding-bottom: 10px;
        border: none;
        outline: none;
        border-bottom: 1px solid $gray-form;
        color: $green-dark;

        &_error {
          border-bottom: 1px solid $red;
        }

        &::placeholder {
          font-size: 0.8571rem;
          color: $light-gray;
          background-color: transparent;
        }

        &:focus {
          border-color: $green-primary;
        }

        &[name='firstName'] {
          margin-bottom: 30px;
        }

        &[type='date'] {
          padding-left: 10px;
          margin-right: 20px;
          min-width: 138px;
        }

        &[type='time'] {
          padding: 0 0 10px 10px;
          min-width: 82px;
        }
      }

      .questionnaire-divider {
        margin: 40px 0;
        @include _768 {
          margin: 60px 0;
        }
        &_hidden {
          opacity: 0;
          margin: 0px 0;
        }
      }

      .questionnaire-date-time-wrap {
        display: flex;
        flex-flow: row nowrap;
      }

      .MuiFormControl-root {
        margin-bottom: 33px;

        @include _768 {
          margin-bottom: 42px;
        }
      }

      .MuiFormControl-root_radio {
        margin-bottom: 0;
        color: $green-dark !important;
      }

      .questionnaire-radio-group {
        display: flex;
        flex-flow: column nowrap;
        margin: 0;
        padding: 0;
        outline: none;
        border: none;

        .questionnaire-radio-desk {
          align-self: flex-start;
          display: flex;
          align-items: center;
          user-select: none;

          @include _768 {
            padding-bottom: 10px;
          }
        }

        .questionnaire-radio-desk-multiple {
          @include _768 {
            width: 46%;
          }
        }

        .rating-question {
          padding: 4px 0;

          @include _768 {
            width: auto;
            margin-right: 0;
            padding-right: 0;
          }
        }

        .MuiIconButton-root {
          padding: 8px;
        }

        .MuiIconButton-colorSecondary {
          margin-right: 10px;
        }

        .MuiSvgIcon-root {
          width: 26px !important;
          height: 26px !important;
        }

        .MuiIconButton-label .MuiSvgIcon-root:first-child {
          fill: $gray-form;
        }
      }

      .questionnaire-radio-group.multiple {
        margin-bottom: 15px;

        @include _768 {
          justify-content: flex-start;
          margin-bottom: 35px;
        }
      }

      .questionnaire-radio-group.multiple_restaurants {
        margin-bottom: 35px;
      }

      .questionnaire-radio-group.rating {
        .option-question {
          @include _768 {
            width: 100%;
          }
        }
      }

      .questionnaire-with-option-field {
        @include _768 {
          width: 100%;
        }

        textarea {
          height: 32px;
          resize: none;
          padding: 0 30px 0 0;
        }
      }

      .questionnaire-upload-field {
        display: flex;
        flex-flow: column nowrap;

        @include _768 {
          flex-flow: row nowrap;
        }

        .upload-field-btn {
          min-width: 175px;
          align-self: flex-start;
          padding: 10px 20px;

          @include _768 {
            margin-right: 20px;
          }
        }

        .upload-field-list {
          display: flex;
          flex-flow: column nowrap;

          @include _768 {
            flex-grow: 1;
          }

          .upload-field-item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .upload-field-icon {
              margin-right: 9px;
              font-size: 12px;
            }

            .upload-field-desc {
              margin-right: 8px;
              font-size: 0.8571rem;
              color: $gray-form-text;
              word-break: break-all;
            }

            .upload-field-clean-btn {
              z-index: 900;
              min-width: 40px;
              height: 40px;
              border: none;
              cursor: pointer;
              background: inherit;

              &:before {
                font-size: 9px;
              }

              @include _768 {
                margin-left: auto;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .questionnaire-submit-btn {
      padding: 14px 20px;
      min-width: 205px;
      border-radius: 23px;
    }

    .questionnaire-option-wrap {
      width: 100%;
    }
  }
}

.icon-checkbox-check:before {
  content: '\e909';
  color: #aec719;
}

.icon-close:before {
  content: '\e90d';
  color: $gray-form-text;
}
