@import '../../../media';
@import '../../../assets/colors';

.product-card__content-top {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 45px;

  .product-card__title {
    color: $green-dark;
  }

  .product-card__tag-container {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    row-gap: 5px;
    flex-wrap: wrap;
  }

  .product-card__info {
    display: flex;
    justify-content: space-between;

    .product-card__weight {
      font-size: 0.8571rem;
      font-weight: 500;
      color: $medium-gray;
    }
  }
}

.product-card__desc {
  max-width: 370px;
  font-size: 0.8571rem !important;
  line-height: 1.5;
  color: $medium-gray;
  letter-spacing: 0.6px;

  @include _768 {
    max-width: 370px;
    font-size: 0.9286rem !important;
    line-height: 1.7;
    letter-spacing: 0.65px;
  }
}
