@import '../../assets/colors';
@import '../../media';

.MuiDrawer-root {
  position: absolute;
}

.BasicSidebar {
  position: absolute;

  .category-icon {
    width: 18px;
    height: 23px;
    margin-right: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain !important;
    flex-shrink: 0;
    font-size: 23px !important;

    @include _768 {
      margin-right: 27px;
    }
  }

  .category-font-icon {
    margin-right: 25px;

    @include _768 {
      margin-right: 27px;
    }

    span {
      &:before {
        font-size: 17px;
        color: $green-primary;
      }
      @include _768 {
        opacity: 0.45;
        &:before {
          font-size: 18px;
          color: white;
        }
      }
    }

    .icon-e92a:before {
      content: '\e92a';
    }

    .icon-e92c:before {
      content: '\e92c';
    }

    .icon-e907:before {
      content: '\e907';
    }

    .icon-e929:before {
      content: '\e929';
    }

    .icon-e92b:before {
      content: '\e92b';
    }

    .icon-e92d:before {
      content: '\e92d';
    }

    .icon-e948:before {
      content: '\e948';
    }
  }

  .MuiListItem-gutters {
    font-size: 1rem !important;

    &:hover * {
      opacity: 1 !important;
    }
  }

  padding: 105px 0 0;

  .MuiPaper-root {
    background-color: #284040 !important;

    .MuiList-root {
      width: 245px;
      min-width: 245px;
      padding: 0;

      .MuiListItem-gutters {
        display: flex;
        align-items: flex-start;
        padding: 12px 26px !important;
        color: white;
        margin-bottom: 14px;

        &:hover {
          background-color: #203131;
        }

        .MuiListItemText-root {
          margin: 0;

          @include _768 {
            padding-top: 4px;
          }

          span {
            letter-spacing: 0 !important;
            line-height: 1.39 !important;
            @include _768 {
              line-height: 1.237 !important;
            }
          }
        }

        .category-icon {
          opacity: 0.45;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 260px;
    height: fit-content !important;
    padding: 100px 0 70px 0;

    .MuiPaper-root {
      width: 260px;
      height: fit-content !important;
      background-color: white !important;

      .MuiList-root {
        width: 260px;
        min-width: 260px;

        .MuiListItem-gutters {
          display: flex;
          align-items: center;
          padding: 20px 30px !important;
          color: black;
          margin-bottom: 0;
          background-color: transparent !important;

          * {
            opacity: 1 !important;
          }

          svg * {
            fill: $green-primary !important;
          }
        }
      }
    }
  }
}

[class^='SparseDivider'] {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    height: 1px;
    background-position: bottom;
    background-size: 20px 1.3px;
    background-repeat: repeat-x;
    width: 100%;
  }
}

.SparseDivider {
  &-smallScreen {
    padding: 20px 30px;

    span {
      background-image: linear-gradient(
        to right,
        #cedfde 50%,
        rgba(255, 255, 255, 0) 0%
      );
    }
  }

  &-largeScreen {
    padding: 20px 12px 35px;

    span {
      background-image: linear-gradient(
        to right,
        white 50%,
        rgba(255, 255, 255, 0) 0%
      );
      opacity: 0.4;
    }
  }
}

.MuiButtonBase-root.MuiListItem-root.sidebar__link__wrapper.MuiListItem-gutters {
  padding: 0 !important;
}

.sidebar__link {
  display: flex;
  padding: 12px 26px;
  width: 100%;
  color: inherit;
}
