@import './src/media';
@import './src/assets/colors';

.delivery-check-areas {
  display: -webkit-box;
  display: -ms-flexbox;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 40px 25px 50px;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @include _768 {
    margin-top: 3px;
    padding: 60px;
  }
}

.delivery-areas__title {
  margin-bottom: 30px;
  font-size: 1.143rem;
  line-height: 1.5 !important;
  color: $green-dark;

  @include _768 {
    margin-bottom: 35px;
    font-size: 1.714rem;
    line-height: 1.25 !important;
  }

  & br {
    display: none;

    @include _768 {
      display: block;
    }
  }
}

.delivery-check-areas__map {
  height: 220px;
  background-color: $light-green;
  margin-bottom: 45px;

  @include _768 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    height: 260px;
    margin-bottom: 0;
  }
}

.delivery-areas__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  width: 100%;

  @include _768 {
    margin-bottom: 40px;
  }
}

.delivery-areas__street {
  width: 100%;
  margin-bottom: 40px;

  @include _768 {
    margin-bottom: 0;
  }
}

.delivery-areas__full-address {
  position: relative;
  width: 100%;

  .geo-button {
    top: 0;
    left: 100%;
    transform: translate(-30px, -5px);
  }
}

.delivery-areas__house {
  width: calc(50% - 10px);

  @include _768 {
    width: calc(20% - 15px);
  }
}

.text-field__error-house {
  position: absolute;
  top: 30px;
  padding-top: 5px;
  text-align: right;
  font-size: 0.8571rem;
  color: $alert;
}

.delivery-areas__flat {
  width: calc(50% - 10px);

  @include _768 {
    width: calc(20% - 15px);
  }
}

.icon-gps-fixed-indicator:before {
  content: '\e931';
  color: $green-primary-dark;
}

.error-map-div {
  min-height: 18px;

  &_address-check {
    bottom: 135px;
    top: auto;

    @include _768 {
      top: 120px;
    }
  }
}

#street-autocomplete {
  color: $green-dark;

  &::placeholder {
    font-size: 0.8571rem;
    color: $light-gray;
  }
}
