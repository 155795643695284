@import '../../media';

.medium-divider-wrapper {
  overflow: hidden;
  margin-right: -25px;
  margin-left: -25px;

  @include _768 {
    margin-right: -60px;
    margin-left: -60px;
  }

  .medium-divider {
    border-bottom: 1px dashed #cedfde;
    transform: scaleX(3.5);
  }
}
