.message-modal-container {
  padding-bottom: 15px;

  .message-modal-title {
    margin-bottom: 40px;
  }

  .message-modal-btn {
    min-width: 220px;
    padding: 10px 20px;

    @media (min-width: 768px) {
      min-width: 100px;
      padding: 10px 40px;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 3px;
  }
}
