@import '../../media';

h1,
ul {
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  box-sizing: border-box;
}

.menu {
  width: 100%;
  height: 100%;

  .menu_section {
    width: 100%;
    display: flex;
    flex-direction: column;

    .menu-heading {
      font-size: 1.714rem !important;

      @include _768 {
        font-size: 2.571rem !important;
      }
    }

    .menu_list {
      max-width: 845px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 35px;

      @include _992 {
        width: 845px;
      }
      @include _768_992 {
        width: 615px;
      }
      @include _576_767 {
        max-width: 465px;
      }
    }
  }
}

.menu_item {
  list-style-type: none;
  display: flex;
  position: relative;
  width: calc(50% - 7.5px);
  height: 100px;
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include _992 {
    width: calc(33.33% - 20px);
    margin-bottom: 30px;
    height: 195px;
  }

  @include _768_992 {
    height: 195px;
  }
}

.changeToBig {
  @include _992 {
    width: calc(50% - 15px);
    height: 195px;
  }
}

.menu_item--big {
  list-style-type: none;
  display: flex;
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include _992 {
    width: calc(50% - 15px);
    height: 195px;
  }

  @include _768_992 {
    height: 195px;
  }
}

.changeToSmall {
  @include _992 {
    width: calc(33.33% - 20px);
    margin-bottom: 30px;
    height: 195px;
  }
}

.menu_item--dark {
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 2;
}

.menu_page {
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  padding: 15px;
  line-height: 1.2;
  font-weight: bold;
  color: white;
  font-size: 0.86rem;
  text-transform: uppercase;

  @include _768 {
    padding: 30px;
    font-size: 1.714rem !important;
  }

  @include _768_992 {
    padding: 30px;
  }

  @include _350 {
    font-size: 1.143rem;
  }
}

[class^='menu_item'] {
  background-size: cover;
  background-position: center;
}
