.dish-unavailable-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.dish-unavailable-title {
  text-decoration: none !important;
  cursor: unset;
  color: #709594;
}
