@import 'assets/colors';
@import 'media';

@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon/icomoon.eot?8k5xd6');
  src:
    url('./assets/fonts/icomoon/icomoon.eot?8k5xd6#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/icomoon/icomoon.ttf?8k5xd6') format('truetype'),
    url('./assets/fonts/icomoon/icomoon.woff?8k5xd6') format('woff'),
    url('./assets/fonts/icomoon/icomoon.svg?8k5xd6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'GothamPro';
  src: url('./assets/fonts/GothamPro/GothamProRegular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'GothamPro';
  src: url('./assets/fonts/GothamPro/GothamProBlack.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'GothamPro';
  src: url('./assets/fonts/GothamPro/GothamProMedium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

* {
  box-sizing: border-box;
  font-family: 'GothamPro', Verdana, sans-serif !important;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light-green;
}

.button-standard {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background-color: $green-primary;
  font-size: 1rem !important;
  font-weight: 700;
  border-radius: 18px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: 0.2s all;

  @media (min-width: 768px) {
    white-space: normal;
  }

  &:disabled {
    border: 1px solid #cedfde;
    color: #709594;
    cursor: default;
    background-color: transparent !important;
  }

  &:hover {
    background-color: #96af04;
  }
}

.flier-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  pointer-events: none;
}

.link,
.green-link {
  cursor: pointer;
  color: $green-primary;
  transition: all 0.3s;
}

.link {
  background-color: $light-green;
}

.green-link {
  text-decoration: none;
}

.green-link:hover {
  text-decoration: underline;
}

button {
  &:active,
  &:focus {
    outline: none;
  }
}

.divider {
  width: 100%;
  border-bottom: 1px dashed #cedfde;
  transform: scaleX(3.5);
}

.empty-button {
  border: none;
  background: none;
  &_place_login {
    margin-bottom: 30px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.invisible {
  visibility: hidden;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  justify-content: center;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spinner-wrapper {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.full-height {
  min-height: 100vh;
}

.spinner-wrapper-absolute {
  position: absolute;
  z-index: 25;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  height: 100vh;

  @include _768 {
    left: 70px;
  }

  @include _1600 {
    left: 245px;
  }
}

.MuiCircularProgress-root * {
  stroke: $green-primary !important;
}

.heading {
  display: block;
  box-sizing: border-box;
  font-size: 1.544rem !important;
  line-height: 1.25 !important;
  color: $green-dark;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 15px;

  @include _768 {
    font-size: 2.571rem !important;
    margin-bottom: 0;
  }
}

.icon-cart:before {
  content: '\e907';
}

// Заголовки страниц

.Page-header {
  position: relative;
  z-index: 2;
  margin-bottom: 25px;

  @include _768 {
    display: flex;
    align-items: flex-end;
  }
}

.simple-flex,
[class^='icon-'],
[class*=' icon-'] {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.justify-center {
  justify-content: center;
}

.pos-relative {
  position: relative;
}

.center-wrapper {
  width: 100%;
  padding-right: 20px !important;
  padding-left: 20px !important;
  margin-right: auto;
  margin-left: auto;
  height: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
    padding-left: 55px !important;
  }

  @media (min-width: 768px) {
    max-width: 710px;
    padding-left: 75px !important;
  }

  @media (min-width: 992px) {
    max-width: 950px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1350px) {
    max-width: 1290px;
  }

  @include _1900 {
    max-width: 1450px;
  }

  @include _2270 {
    max-width: 1810px;
  }
}

.inner-container {
  max-width: 845px;
  margin: 0 auto;
}

.inner-container--lg {
  max-width: 1135px;
}

.inner-container--md {
  max-width: 1095px;
}

.inner-container--xsm {
  max-width: 750px;
}

.inner-container--sm {
  max-width: 615px;
}

.inner-container--xs {
  max-width: 570px;
}

.breadcrumbs {
  margin-bottom: 40px;
}

.breadcrumbs__sep {
  margin: 0 10px;
}

.text-field {
  position: relative;
}

.text-field__input {
  display: block;
  width: 100%;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px solid $gray-form;
  font-size: 1rem;
  line-height: 1.2;
  color: $green-dark;
}

.text-field__input::-webkit-input-placeholder {
  font-size: 0.8571rem;
  color: $gray-form-text;
}

.text-field__input::-moz-placeholder {
  font-size: 0.8571rem;
  color: $gray-form-text;
}

.text-field__input:-ms-input-placeholder {
  font-size: 0.8571rem;
  color: $gray-form-text;
}

.text-field__input::-ms-input-placeholder {
  font-size: 0.8571rem;
  color: $gray-form-text;
}

.text-field__input::placeholder {
  font-size: 0.8571rem;
  color: $gray-form-text;
}

.text-field__input:focus {
  outline: none;
  border-color: $green-primary;
}

.text-field--error .text-field__input {
  border-color: $text-filed-error;
}

.text-field--error .text-field__error-message {
  display: block;
}

.text-field__error-message {
  display: none;
  position: absolute;
  right: 0;
  padding-top: 5px;
  text-align: right;
  font-size: 0.8571rem;
  color: $alert;
}

.button-secondary {
  background-color: #e6eeee;
  color: $medium-gray;
  z-index: 5;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  font-weight: bold;
  text-transform: uppercase;
  transition:
    background-color 0.3s,
    color 0.3s;

  &:hover {
    background-color: $green-primary;
    color: white;
  }
}

.button-white {
  background-color: $white;
  color: $light-gray;
  border: 1px solid $light-gray;

  @include _768 {
    border-width: 2px;
  }

  &:hover {
    background-color: $green-hover;
    border-color: $green-hover;
    color: $white;
  }
}

.general-wrapper {
  @media (min-width: 1600px) {
    padding-left: 110px;
  }
}

.section-wrapper-medium {
  margin: 0 auto;

  @include _576 {
    max-width: 465px;
  }

  @include _768 {
    max-width: 615px;
  }

  @include _992 {
    max-width: 750px;
  }
}

.section-wrapper-ms {
  margin: 0 auto;
  max-width: 615px;
}

.paper-medium {
  display: flex;
  flex-flow: column nowrap;
  padding: 40px 20px 50px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @include _768 {
    padding: 50px 45px;
  }
}

.rub-sign {
}

.pointer {
  cursor: pointer;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-60 {
  margin-bottom: 60px;
}

.with-default-bgi {
  background-size: cover, cover !important;
  background-position: center, center !important;
  background-repeat: no-repeat, no-repeat !important;
}

.no-margin {
  margin: 0 !important;
}

.no-shrink {
  flex-shrink: 0;
}

.show-with-opacity {
  animation: with-opacity 0.4s ease-in-out;
}

@keyframes with-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.on-stop {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
