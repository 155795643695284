.invalid {
  color: red;
}

.dropdown {
  position: relative;
}
.dropdown__label {
  position: relative;
  font-size: 0.8571rem;
  color: #92b3b2;
  z-index: 2;
}
.dropdown__current-item {
  min-height: 41px !important;
  position: relative;
  padding: 14px 15px 10px 0;
  font-size: 1rem;
  color: #3d5b5a;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #cedfde;
  cursor: pointer;
  z-index: 2;

  &--with-clock {
    padding-left: 30px;
  }
}
.dropdown__list-options {
  display: none;
  position: absolute;
  left: -15px;
  top: -20px;
  width: calc(100% + 30px);
  padding: 74.5px 0 13px;
  background-color: #fff;
  border-top: none;
  z-index: 1;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.dropdown__list-options-inner {
  max-height: 173px;
  overflow-y: auto;
}
.dropdown__option {
  padding: 13px 15px;
  font-size: 1rem;
  color: #92b3b2;
}
.dropdown__option:hover {
  color: #a7c205;
}
.dropdown__arrow {
  position: absolute;
  right: 0;
  top: 16px;
  font-size: 10px;
  -webkit-transition: 0.3s -webkit-transform ease;
  transition: 0.3s -webkit-transform ease;
  transition: 0.3s transform ease;
  transition:
    0.3s transform ease,
    0.3s -webkit-transform ease;
}
.dropdown .dropdown__icon {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 28px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}

.dropdown--open {
  z-index: 3;

  .dropdown__current-item:has(.dropdown__current-item__value) {
    border-bottom: none;
  }
}

.dropdown--open .dropdown__list-options {
  display: block;
}

.dropdown--open .dropdown__arrow {
  -webkit-transform: translateY(-5px) rotate(180deg);
  transform: translateY(-5px) rotate(180deg);
}

.dropdown--icon .dropdown__current-item {
  padding-left: 40px;
}

.dropdown--icon .dropdown__option {
  position: relative;
  padding-left: 55px;
}

.dropdown--icon .dropdown__option .dropdown__icon {
  left: 15px;
}

.dropdown--icon .dropdown__option .dropdown__icon span:before {
  color: #92b3b2;
}

.dropdown--icon .dropdown__option:hover .dropdown__icon span:before {
  color: #a7c205;
}

.dropdown__time-icon {
  position: absolute;
  left: 0;
  top: 13px;
  font-size: 18px;
}

.icon-clock {
  font-size: 18px;

  &::before {
    content: '\e90b';
    color: #aec719;
  }
}

.icon-arrow_dropdown {
  font-size: 10px;

  &::before {
    content: '\e902';
    color: #486463;
  }
}
