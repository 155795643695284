@import '../../media';
@import '../../assets/colors';

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  min-height: 65vh;
  padding: 15px;

  &__wrp {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    color: $green-dark;

    @media (max-width: 992px) {
      flex-direction: column;
    }
  }

  &__header {
    font-size: 100px;
    font-weight: 400;

    @media (max-width: 576px) {
      font-size: 60px;
    }
  }

  &__decription {
    font-size: 50px;
    font-weight: 400;
    margin: 0;

    @media (max-width: 576px) {
      font-size: 40px;
    }
  }

  &__link,
  &__advice {
    font-size: 30px;
    width: 350px;

    @media (max-width: 992px) {
      width: 100%;
    }

    @media (max-width: 576px) {
      font-size: 25px;
    }
  }

  &__link {
    font-weight: 500;
    color: $green-dark;
  }

  &__text {
    margin-right: 150px;

    @media (max-width: 992px) {
      margin-bottom: 100px;
      margin-right: 0;
    }

    @media (max-width: 350px) {
      margin-bottom: 50px;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 992px) {
      justify-content: center;
    }

    img {
      display: block;
      max-width: 300px;
      height: auto;

      @media (max-width: 767px) {
        max-width: 250px;
      }

      @media (max-width: 350px) {
        max-width: 150px;
      }
    }
  }
}
