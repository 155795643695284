@import 'assets/colors';
@import 'media';

.app-content {
  padding-top: 100px;
  padding-bottom: 50px;
  min-height: calc(100vh - 236.4px);
  order: 1;
  flex: 1;

  @include _992 {
    min-height: calc(100vh - 83.6px);
  }

  @include _768 {
    padding-top: 130px;
  }
}

.MuiScopedCssBaseline-root {
  background-color: $light-green !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
