$green-primary: #a7c205;
$green-dark: #3d5b5a;
$light-gray: #92b3b2;
$medium-gray: #709594;
$light-green: #eef3f3;
$green-primary-dark: #96b230;
$super-light-gray: #e7efee;
$white: #fff;
$gray-form: #cedfde;
$gray-form-text: #92b3b2;
$green-hover: #96af04;
$alert: #ec704b;
$orange: #f93;
$purple: #8b2d87;
$red: #d34a4a;
$blue: #06c;
$green: #2d9462;
$citrus: #a9c30a;
$text-filed-error: #f69071;
