@import '../../media';
@import '../../assets/colors';

.menuPage-heading {
  margin-bottom: 20px;
  font-size: 1.714rem !important;

  @include _768 {
    margin-bottom: 0;
    font-size: 2.571rem !important;
  }
}

.MuiChip-root {
  &:hover {
    background-color: $green-primary !important;
    color: white !important;
  }
}

body .MuiChip-clickable {
  &:focus {
    background-color: #cedfde;
  }
}

.active {
  background-color: $green-primary !important;
  color: white !important;
}

.number-of-dishes {
  font-size: 1.143rem;
  line-height: 1.65;
  color: $light-gray;
}

.absolute-heading {
  position: sticky !important;
  top: 70px;
  z-index: 6;
  background-color: $light-green;
  width: 108%;
  margin-left: -8px;
}

.chip-container {
  margin-bottom: 6px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media (min-width: 768px) {
    margin-bottom: 0;
    flex-wrap: wrap;

    .MuiChip-root {
      margin-bottom: 7px !important;
    }
  }

  @media (min-width: 1350px) {
    margin-bottom: 23px;

    .MuiChip-root {
      margin-bottom: 14px !important;
    }
  }
}

.menu-page-nav-root {
  margin-bottom: 20px;

  @include _768 {
    margin-bottom: 30px;
  }
}

.categories-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 20px 0 !important;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.category-title {
  margin-bottom: 20px;
  font-size: 1.571rem !important;
  font-weight: 700;
  color: $medium-gray;

  .anchor {
    display: block;
    position: relative;
    top: -220px;
    visibility: hidden;
  }
}

.category-title__none {
  display: none;
}
