@import '../../../media';
@import '../../../assets/colors';

.mobile-app {
  display: none;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 25px 25px 0;
  background-color: #fff;
  clip-path: polygon(35px 0, 100% 0, 100% 100%, 0 100%, 0 35px);

  @include _768 {
    display: flex;
    margin-top: 70px;
  }

  &__shadow {
    filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.1));
  }

  .mobile-app__content {
    margin-right: 70px;

    @include _992 {
      margin-right: 100px;
    }

    .mobile-app__title {
      margin-bottom: 30px;
      font-size: 1.286rem !important;
      color: $green-dark;
      font-weight: 700;
      text-transform: uppercase;
    }

    .mobile-app__links {
      display: flex;
      flex-direction: column;

      @include _992 {
        flex-direction: row;
      }

      .mobile-app__link {
        width: 110px;
        height: 33px;
        display: block;

        &:first-child {
          margin-bottom: 10px;

          @include _992 {
            margin-bottom: 0;
            margin-right: 20px;
          }
        }

        .link-img {
          width: inherit;
          height: inherit;
        }
      }
    }
  }

  .mobile-app__image {
    width: 275px;
    height: 253px;
    object-position: top;
    object-fit: cover;
  }
}
