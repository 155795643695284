.popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 1303;
  background: #d9d9d9ab;
  backdrop-filter: blur(3px);

  &__container {
    position: relative;
    max-width: 525px;
    width: 100%;
    height: fit-content;
    margin: auto;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
    filter: drop-shadow(0 0px 10px rgba(0, 0, 0, 0.2));

    @media (max-width: 767px) {
      max-width: 360px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 1117%;
      height: 160%;
      top: 30px;
      right: -501%;
      background: #fff;
      transform-origin: 47% 0;
      transform: rotate(-45deg);
      z-index: -1;
      @media (max-width: 767px) {
        width: 1125%;
        height: 155%;
      }
    }
  }

  &__message-container {
    padding: 35px 60px 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 767px) {
      padding: 20px 23px;
    }
  }
  &__header {
    margin: 0 auto 42px;
    font-weight: 500;
    font-size: 14px;
    color: #3d5b5a;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 30px;
      font-size: 12px;
    }
  }

  &__message {
    margin: 0 auto 45px;
    text-align: center;
    font-size: 19px;
    color: #3d5b5a;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 15px;
    }
  }
  &__button {
    display: inline-block;
    padding: 10px 20px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #a7c205;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 18px;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: 0.2s all;

    @media (max-width: 767px) {
      padding: 8px 17px;
    }
    &_close {
      border-radius: 0;
      height: 30px;
      width: 30px;
      padding: 0;
      background-image: url('../../assets/icons/close.svg');
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: center;
    }
  }
}
