@import '../../../media';
@import '../../../assets/colors';

.dishes-icons-bar {
  position: absolute;
  display: flex;
  z-index: 10;
  top: -8px;
  right: -8px;

  &__img {
    width: 25px;
    height: 25px;

    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
}

.dishes-icons-bar_big {
  top: -12px;
  right: -8px;

  .dishes-icons-bar__img {
    width: 35px;
    height: 35px;

    &:not(:first-of-type) {
      margin-left: 15px;
    }
  }
}

.cart-item {
  .dishes-icons-bar {
    justify-content: flex-end;
    top: -8px;
    left: -8px;
    width: 78px;

    @include _992 {
      width: 116px;
    }

    &__img {
      width: 15px;
      height: 15px;

      &:not(:first-of-type) {
        margin-left: 5px !important;
      }

      @include _992 {
        width: 22px;
        height: 22px;

        &:not(:first-of-type) {
          margin-left: 10px !important;
        }
      }

      &:not(:first-of-type) {
        margin-left: 10px;
      }
    }
  }
}
