@import '../../assets/colors';
@import '../../media';

.empty-request {
  padding: 80px 25px 125px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  .icon-empty-request {
    width: 85px;
    height: 85px;
    margin: 0 auto 35px;
  }

  .icon-empty2:before {
    content: '\e913';
    color: #e5eeee;
    font-size: 85px !important;
  }

  .empty-request__text {
    text-align: center;
    font-size: 1rem !important;
    line-height: 1.65 !important;
    color: $light-gray;

    @include _768 {
      font-size: 1.286rem !important;
    }
  }
}
