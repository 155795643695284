@import '../../media';
@import '../../assets/_colors';

.special-offers-wrapper {
  margin: 0 auto;
  max-width: 1135px;

  .special-offers-heading {
    margin-bottom: 35px;
    font-size: 1.714rem !important;
    line-height: 1.25;

    @include _768 {
      font-size: 2.571rem !important;
    }
  }

  .special-offers-list {
    display: flex;
    flex-flow: column;

    @include _768 {
      flex-flow: wrap;
      justify-content: space-between;
    }
  }
}

.offer-card-simple {
  @include _768 {
    width: 48% !important;
  }
}

.offer-card {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 50px;
  width: 100%;
  background-color: white;

  @include _768 {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      border-top: 80px solid $light-green;
      border-left: 80px solid transparent;
    }
  }

  .offer-card-img {
    width: 100%;
    height: 300px;
  }

  .offer-card-content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    padding: 50px 30px 30px 30px;

    .offer-card-title {
      display: block;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      margin-top: 0;
      margin-bottom: 20px;

      @include _768 {
        font-size: 1.571rem !important;
      }
    }

    .card-desc-full {
      margin-bottom: 50px;
    }

    .card-desc-simple {
      display: block;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 4;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      margin-bottom: 80px;
    }

    .offer-card-desc {
      margin-top: 0;
      font-size: 1.143rem;
      line-height: 1.7 !important;
      color: $green-dark;
    }

    .offer-card-link {
      margin-top: auto;
      margin-bottom: 0;
    }
  }
}
