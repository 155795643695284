@import '../../media';
@import '../../assets/colors';

.MuiCardMedia-root {
  height: 150px;

  @include _768 {
    height: 320px;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $green-primary !important;
}

.MuiRadio-root {
  color: #cedfde !important;
  border-width: 1px !important;
}

.product-card--big .MuiTypography-root {
  padding-left: 12px !important;
  white-space: initial;
  font-family: unset !important;
  color: $green-dark !important;
}

.product-card--big .MuiCardContent-root {
  padding: 30px 20px !important;

  @include _768 {
    height: calc(100% - 320px) !important;
    padding: 45px 55px !important;
  }
}

.dish_card {
  position: relative !important;
  overflow: visible !important;

  .tooltip-icons__img {
    width: 35px;
    height: 35px;
  }

  .medium-divider-wrapper {
    margin-left: -15px;
    margin-right: -15px;

    @include _768 {
      margin-left: -55px;
      margin-right: -55px;
    }
  }
}

.price-button-wrapper {
  @include _768 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .price-button-wrapper__price {
    margin-bottom: 20px;

    @include _768 {
      margin-bottom: 0;
    }

    .price-button-wrapper__title {
      display: inline-block;
      margin-right: 10px;
      font-size: 1.143rem;
      font-weight: 700;
      color: $green-dark;
    }

    .price-button-wrapper__value {
      display: inline-block;
      font-size: 1.714rem;
      line-height: 1;
      font-weight: 700;
      color: $green-dark;

      .rub-sign {
        @include _768 {
          font-size: 1.429rem;
          line-height: 1;
        }
      }
    }
  }

  .price-button-wrapper__dish-button {
    width: 100%;
    padding: 15px 20px;
    border-radius: 23px;

    @include _768 {
      width: unset;
      padding: 15px 50px;
    }
  }
}

.flying-button {
  padding: 15px 20px;
  border-radius: 23px;
  cursor: default;
  width: 100%;
  pointer-events: none;

  &:hover {
    background-color: $green-primary;
  }

  @include _768 {
    padding: 15px 50px;
  }
}

.constructor__divider {
  margin-bottom: 30px;
}

.constructor__radio {
  margin-bottom: 30px;
}

.radio__title {
  font-size: 1.143rem;
  font-weight: 700;
  color: $green-dark;
  margin-bottom: 25px;
}

.constructor__food-switcher {
  margin-bottom: 25px;

  .food-switcher__title {
    max-width: 270px;
    font-size: 1rem;
    color: $green-dark;
  }

  .food-switcher__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    @include _992 {
      height: 30px;
    }

    .food-switcher__price {
      font-size: 1.143rem;
      font-weight: 700;
      color: $green-dark;
    }

    .amount-switcher {
      display: flex;
      align-items: center;

      @include _992 {
        position: relative;
        top: -14px;
      }
    }
  }
}

.constructor-price {
  font-size: 0.8571rem;
  color: $medium-gray;
  margin-bottom: 20px;

  .constructor-price__big {
    font-size: 1.143rem;
    font-weight: 700;
  }
}

.MuiFab-root {
  background-color: $green-primary !important;
  width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;

  @include _992 {
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
  }
}

.amount-switcher__value {
  min-width: 25px;
  text-align: center;
  font-size: 1.286rem;
  color: $green-dark;
  margin: 0 7px;
  position: relative;
  top: 0.1rem;
  line-height: 1;

  @include _992 {
    margin: 0 10px;
  }
}

.Fab-content__minus {
  &:before {
    content: '\2212';
  }
}

.Fab-content__plus {
  &:before {
    content: '\002B';
  }
}

.Fab-content__plus,
.Fab-content__minus {
  position: relative;
  font-family: 'Georgia', serif !important;

  &:before {
    line-height: 1em;
    font-size: 18px;
    position: absolute;
    top: -0.75rem;
    left: -0.5rem;
    right: -0.5rem;
  }
}

.product-card--big {
  @include _768 {
    padding-top: 3px;
  }
}

.product-card--big .MuiCardContent-root {
  @include _768 {
    padding: 45px 55px !important;
  }
}

.product-card--big .product-card__title {
  max-width: 370px;
  font-weight: 700;

  @include _768 {
    max-width: 370px;
    margin-bottom: 4px;
    font-size: 1.571rem !important;
  }
}

.product-card--big .product-card__content-top {
  margin-bottom: 55px;
}

.product-card--big .product-card__info {
  margin-bottom: 30px;
}

body .product-card--big .product-card__weight {
  @include _768 {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: 1rem !important;
  }
}

.product-card--big .product-composition__item {
  margin-right: 20px !important;

  @include _768 {
    margin-right: 40px !important;
  }

  &:last-child {
    margin-right: 0;
  }
}

.product-card--big .product-composition__title {
  @include _768 {
    font-size: 0.8571rem !important;
  }
}

.product-card--big .product-composition__value {
  @include _768 {
    font-size: 1.143rem !important;
  }
}

.related-products {
  padding-top: 40px;

  @include _768 {
    padding-top: 55px;
  }

  .related-products__title {
    margin-bottom: 28px;
    font-size: 1.143rem;
    font-weight: 700;
    color: $green-dark;
    text-transform: uppercase;

    @include _768 {
      font-size: 1.429rem;
    }
  }

  .related-products__list {
    display: flex;
    overflow-x: auto;

    @include _768 {
      overflow-x: unset;
    }

    .MuiCard-root {
      flex-shrink: 0;
      width: 176px;
      margin-right: 20px;
      background-color: $white;

      .MuiCardMedia-root {
        height: 100px;
        cursor: pointer;
      }

      .MuiCardContent-root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 100px) !important;
        padding: 20px 15px !important;

        .related-products__item-title {
          display: inline-block;
          min-height: 29px;
          margin-bottom: 12px;
          font-size: 0.8571rem;
          color: $green-primary;
          transition: all 0.3s;
        }
      }
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: $white !important;
}

.product-card__note {
  margin-top: 1rem;
  margin-bottom: 0;
  color: $medium-gray;
  font-size: 0.8571rem !important;

  @include _768 {
    font-size: 0.9286rem !important;
  }
}

.note {
  display: flex;
  justify-content: flex-end;
  font-size: inherit;
  font-style: italic;

  @include _768 {
    padding-left: 30%;
  }

  .note__icon {
    margin-left: 5px;
    color: $green-dark;
  }

  .note__desc {
    text-align: right;
    font-size: inherit;
  }
}

.dish-page {
  &__related-dish-btn {
    margin-left: auto;
  }
}
