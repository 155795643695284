@import '../../media';
@import '../../assets/_colors';

@keyframes click-underline {
  0% {
    left: 50%;
    right: 50%;
  }

  100% {
    left: 0;
    right: 0;
  }
}

#root[aria-hidden] .profile-form-datepicker .MuiButtonBase-root {
  filter: invert(77%) sepia(27%) saturate(6961%) hue-rotate(28deg)
    brightness(102%) contrast(96%);
}

.profile-section {
  position: relative;
  margin: 0 auto;
  max-width: 570px;

  .profile-title {
    margin-bottom: 35px;
    @media (max-width: 767px) {
      font-size: 1.714rem !important;
    }
  }

  .profile-form {
    display: flex;
    flex-flow: column nowrap;
    padding: 25px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    @include _768 {
      padding: 45px 60px 50px;
    }

    .profile-header-bar {
      display: flex;
      flex-flow: column nowrap;
      margin-bottom: 30px;

      @include _768 {
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 40px;
      }

      .profile-points {
        display: flex;
        align-content: flex-end;
        margin-right: 60px;
        margin-bottom: 5px;
        * {
          line-height: 1 !important;
        }

        @include _768 {
          margin-bottom: 0;
        }
      }

      .icon-points {
        width: 42px;
        margin-right: 18px;

        &:before {
          content: '\e917';
          color: $green-primary;
          font-size: 55px;
        }
      }

      .profile-points-wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .profile-points-title {
        font-size: 1rem;
        color: $light-gray;
        margin-bottom: 1.5px;
      }

      .profile-points-count {
        font-size: 2.571rem;
        font-weight: bold;
        color: $green-dark;
      }
    }

    .profile-divider {
      margin-bottom: 40px;

      @include _768 {
        margin-bottom: 55px;
      }
    }

    .profile-form-label {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      margin-bottom: 40px;
      font-size: 0.9rem;
      color: $gray-form;
      .profile-form-item {
        padding: 1px 1px 10px 1px;
        width: 100%;
        border: none;
        outline: none !important;
        border-bottom: 1px solid $gray-form;
        font-size: 1.143rem;
        color: $green-dark;
        background-color: transparent !important;
        box-shadow: none !important;

        &::placeholder {
          color: $gray-form-text;
          background-color: transparent;
        }

        &:disabled {
          -webkit-text-fill-color: #709594c6;
          background-color: transparent;
        }

        &[aria-invalid] {
          border-bottom: 1px solid $alert;
        }
      }

      .profile-form-item:focus ~ .profile-form-decorator,
      .profile-form-item:active ~ .profile-form-decorator {
        position: absolute;
        left: 50%;
        right: 50%;
        bottom: 0;
        height: 2px;
        background-color: $green-hover;
        animation: click-underline 0.5s ease-in-out;
        animation-fill-mode: forwards;
      }

      .profile-form-item:focus[aria-invalid] ~ .profile-form-decorator,
      .profile-form-item:active[aria-invalid] ~ .profile-form-decorator {
        background-color: $alert;
      }

      .profile-form-message {
        position: absolute;
        right: 0;
        bottom: -1.4rem;
        font-size: 0.8571rem;
        color: $alert;
      }
    }

    .profile-form-label:nth-of-type(4) {
      margin-bottom: 25px;
    }

    .profile-form-item[aria-invalid]:focus ~ .profile-form-desc,
    .profile-form-item[aria-invalid] ~ .profile-form-desc {
      color: $alert;
    }

    .to-bottom {
      .MuiPaper-root.MuiPopover-paper {
        transform: translateY(0);
      }
    }

    .to-top {
      .MuiPaper-root.MuiPopover-paper {
        transform: translateY(-390px) !important;
      }
    }

    .MuiPopover-root {
      z-index: 1500 !important;
    }

    .date-picker-container {
      position: relative;
      display: flex;
      flex-flow: column nowrap;

      .profile-form-datepicker {
        margin-bottom: 40px;

        .MuiFormLabel-root {
          font-size: 1.143rem;
          color: $gray-form-text;
        }

        .MuiScopedCssBaseline-root {
          color: $green-dark;
        }

        .Mui-error {
          color: $alert !important;
        }

        .Mui-focused {
          color: $green-hover !important;
        }

        .MuiInputBase-input {
          padding: 6px 0 17px;
          height: 2.1876em;
          font-size: 1.143rem;
          color: $green-dark;
          outline: none !important;
          background-color: transparent !important;
          box-shadow: none !important;
        }

        .MuiInput-underline:before {
          border-bottom-color: $gray-form;
        }

        .MuiInput-underline:after {
          border-bottom-color: $green-hover;
        }

        .MuiInput-underline.Mui-error,
        .MuiInput-underline.Mui-error:after {
          border-bottom-color: $alert;
        }

        .MuiFormHelperText-root {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        .Mui-error {
          color: $alert !important;
        }

        .MuiSvgIcon-root {
          width: 14px;
        }

        .MuiButtonBase-root:hover {
          transition: filter 0.2s;
        }

        .MuiButtonBase-root:hover,
        .MuiButtonBase-root:active {
          filter: invert(77%) sepia(27%) saturate(6961%) hue-rotate(28deg)
            brightness(102%) contrast(96%);
        }

        .mat-datepicker-toggle-active {
          color: $green-primary !important;
        }
      }

      .MuiPopover-root {
        position: absolute !important;
        top: 0 !important;
        width: 100%;

        .MuiPopover-paper {
          max-height: none !important;
          max-width: none;

          @include _576 {
            max-width: 100%;
          }
        }

        .MuiPaper-root.MuiPopover-paper {
          position: absolute !important;
          top: 54% !important;
          bottom: auto !important;
          left: auto !important;
          right: -10px !important;
          overflow-y: hidden;
          box-shadow:
            0 2px 4px -1px rgba(0, 0, 0, 0.2),
            0 4px 5px 0 rgba(0, 0, 0, 0.14),
            0 1px 10px 0 rgba(0, 0, 0, 0.12);

          @include _350 {
            left: 0 !important;
            right: auto !important;
          }

          .MuiPickersBasePicker-containerLandscape {
            position: relative;
            flex-flow: column nowrap;

            .toolbar-container {
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              padding: 16px 8px 30px 8px;
              height: auto;
              max-width: 100%;
              min-width: 100%;
              border-bottom: 1px solid #e0e0e0;
              background-color: transparent;

              .toolbar-main-btn {
                margin: 0 auto 0 0;
                padding: 16px 30px 16px 13px;
                position: relative;
                text-transform: uppercase;
                font-size: 1rem;
                line-height: 2.571em;
                font-weight: 500;
                color: #212121;

                &:after {
                  content: '';
                  position: absolute;
                  right: 14px;
                  top: 23px;
                  width: 0;
                  height: 0;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  border-top: 5px solid #757575;
                  transform: rotate(180deg);
                  transition: transform 0.3s;
                }
              }

              .toolbar-btn {
                margin: 0;
                padding: 0;
                position: relative;
                width: 38px;
                min-width: 38px;
                height: 38px;
                border-radius: 50%;

                &:after {
                  content: '';
                  position: absolute;
                  width: 9px;
                  height: 9px;
                  border-right: 2px solid #757575;
                  border-bottom: 2px solid #757575;
                }

                &:disabled:after {
                  border-color: #bdbdbd;
                }
              }

              .toolbar-prev-btn.toolbar-btn:after {
                transform: rotate(135deg);
              }

              .toolbar-next-btn.toolbar-btn:after {
                transform: rotate(-45deg);
              }

              .date-month-title {
                position: absolute;
                bottom: -37px;
                left: 20px;
                font-size: 1rem;
                font-weight: 500 !important;
                color: #757575;
              }
            }

            .toolbar-container.date {
              padding: 16px 8px 30px 8px;
            }

            .toolbar-container.date .toolbar-main-btn:after {
              transform: rotate(0);
              transition: transform 0.3s;
            }

            .toolbar-container.year,
            .toolbar-container.month {
              padding: 16px 8px 10px 8px;
            }

            .MuiPickersCalendarHeader-switchHeader {
              position: absolute;
              right: 0;
              top: 20px;
              margin-bottom: 0;
              margin-top: 0;
            }

            .MuiPickersDay-daySelected,
            .MuiPickersDay-daySelected:hover,
            .MuiPickersDay-daySelected:active,
            .MuiPickersDay-daySelected:focus {
              color: #212121;
              background-color: $green-primary;
            }

            .MuiIconButton-label {
              color: #212121;

              .MuiTypography-body2 {
                font-size: 13px;
              }
            }

            .MuiPickersDay-current {
              border: 1px solid #9e9e9e;
              border-radius: 50%;
            }

            .MuiPickersDay-dayDisabled {
              color: #9e9e9e;
            }

            .MuiIconButton-root {
              padding: 10px;
            }

            .MuiSvgIcon-root {
              width: 1.2em;
              height: 1.2em;
            }

            .MuiPickersCalendarHeader-transitionContainer {
              position: absolute;
              left: -204%;
              bottom: -74px;
              overflow: visible;
              width: 200%;
              height: 23px;
              font-weight: 500;
              text-align: left;
              text-transform: uppercase;
            }

            .MuiTypography-root.MuiTypography-body1 {
              text-align: left;
              color: #0000008a;
              font-weight: 500;
            }

            .MuiPickersBasePicker-pickerView {
              min-width: 296px;
              max-width: 296px;
              min-height: 260px;
              justify-content: start;
              overflow: hidden;

              .MuiPickersCalendarHeader-daysHeader {
                position: absolute;
                top: 76px;
              }

              .MuiPickersCalendarHeader-dayLabel {
                font-size: 11px;
                font-weight: 400;
                color: #9e9e9e;
                text-transform: uppercase;
              }

              .MuiPickersCalendar-transitionContainer {
                margin-top: 50px;
                min-height: 209px;
              }

              .MuiPickersDay-day {
                margin-bottom: 4px;
              }
            }
            .MuiPickersDay-dayDisabled > .MuiIconButton-label {
              color: #9e9e9e !important;
            }

            .MuiPickersCalendarHeader-iconButton > .MuiIconButton-label {
              color: #757575;
            }
          }

          .Mui-disabled.Mui-disabled > .MuiIconButton-label {
            color: #bdbdbd !important;
          }

          .MuiPickersBasePicker-pickerView {
            .MuiPickersYearSelection-container {
              overflow-y: hidden;
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              padding: 8px 8px 26px 8px;
              height: 276px;

              .MuiPickersYear-root {
                width: 63px;
                height: 36px;
                font-size: 12px !important;
                line-height: 1em !important;
                text-transform: lowercase;
                color: #212121;
              }

              .MuiPickersYear-root {
                border-radius: 999px;
                border: 1px solid transparent;
                transition: background-color 0.3s;

                &:hover:not(:disabled),
                &:active:not(:disabled),
                &:focus:not(:disabled) {
                  background-color: #f5f5f5;
                }
              }

              .MuiPickersYear-yearDisabled {
                color: #9e9e9e;
              }

              .MuiPickersYear-yearSelected {
                margin: 0;
                width: 60px;
                height: 34px;
                border-color: $green-primary;
                font-size: 13px;
                text-transform: lowercase;
                color: #212121;
                background-color: $green-primary;

                &:hover,
                &:active,
                &:focus {
                  background-color: $green-hover !important;
                }
              }
            }

            .MuiPickersMonthSelection-container {
              overflow-y: hidden;
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              padding: 50px 0 105px 0;
              width: 100%;
              height: 276px;

              .MuiPickersMonth-monthSelected,
              .MuiPickersMonth-root {
                flex: 1 0 25%;
                width: 63px;
                height: 36px;
                border: 1px solid transparent;
                border-radius: 999px;
                font-size: 12px !important;
                line-height: 1em !important;
                font-weight: 400;
                text-transform: uppercase;
                color: #212121;

                &:hover:not(:disabled),
                &:active:not(:disabled),
                &:focus:not(:disabled) {
                  background-color: #f5f5f5;
                }
              }

              .MuiPickersMonth-monthDisabled {
                color: #9e9e9e;
              }
            }
          }
        }
      }
    }

    .six-weeks-container .MuiPickersCalendar-transitionContainer {
      margin-top: 10px !important;
    }

    .notify {
      margin-bottom: 20px;

      .checkbox__custom {
        position: relative;
        margin-right: 20px;
      }
    }

    .profile-passbook-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 40px;
      color: $green-hover;
      font-size: 1rem;
      text-decoration: none;
      cursor: pointer;
      transition: text-decoration 0.2s ease-in-out;

      .profile-passbook-img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
    }

    .profile-passbook-link:hover,
    .profile-passbook-link:active,
    .profile-passbook-link:focus {
      text-decoration: underline;
    }

    .profile-btn-group {
      display: flex;
      flex-flow: column nowrap;

      .btn {
        padding: 10px 40px;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 700;
        border-radius: 18px;
        border-width: 1px;
        border-style: solid;
        cursor: pointer;
        transition:
          background-color 0.2s,
          color 0.2s,
          border-color 0.2s;

        &:disabled {
          cursor: default;
        }
      }

      .profile-cancel-btn {
        margin-bottom: 10px;
        background-color: #e6eeee;
        border-color: #e6eeee;
        color: #709594;

        &:disabled,
        &:disabled:hover,
        &:disabled:active,
        &:disabled:focus {
          color: #709594;
          border-color: #cedfde;
          background-color: transparent;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: $green-primary;
          color: #fff;
          border-color: $green-primary;
        }
      }

      .profile-save-btn {
        background-color: $green-primary;
        border-color: $green-primary;
        color: #fff;

        &:disabled,
        &:disabled:hover,
        &:disabled:active,
        &:disabled:focus {
          border-color: $green-primary;
          background-color: $green-primary;
          opacity: 0.6;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: $green-hover;
          border-color: $green-hover;
        }
      }

      @include _768 {
        flex-flow: row nowrap;
        justify-content: flex-end;

        .profile-cancel-btn {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }
}
