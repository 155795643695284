@import '../../media';
@import '../../assets/_colors';

.place-order {
  &__map-title {
    margin-bottom: 25px;
    color: #3d5b5a;
    font-size: 1.143rem;
  }
  &__map-container {
    margin-bottom: 55px;

    @include _768 {
      margin-bottom: 45px;
    }
  }
  &__map {
    height: 220px;
    background-color: #eef3f3;
  }
  &__address-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;

    .geo-button {
      top: -9px;
      right: 25px;
      z-index: 2;
    }
  }
  &__street {
    width: 100%;
    position: relative;

    .MuiInputBase-input {
      line-height: 1.3;
    }
  }
  &__input-container {
    position: relative;
    margin-bottom: 35px;

    &--sm {
      width: calc(50% - 12px);
    }
  }
  &__adress {
    margin-bottom: 45px;

    input {
      padding-right: 40px;
    }
  }

  &__adress-comtainer {
    position: a;
  }
}

#street-autocomplete::placeholder {
  opacity: 1;
}

.text-field__input {
  &--sm {
    width: calc(50% - 12px);
  }
  &--xs {
    width: calc(33% - 12px);
  }
}

.error-map-div {
  position: absolute;
}

.edit-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &_place-order {
    top: 14px;
  }
}

.icon-edit-address {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: no-repeat center url('../../assets/icons/orders/edit.svg');
}
