@import '../../../assets/colors';
@import '../../../media';

.presence-info-form {
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  &__heading {
    margin-top: 0;
    text-align: center;
    font-size: 1.544rem;
    text-transform: uppercase;
  }

  &__error {
    display: block;
    position: absolute;
    top: 4.5rem;
    right: 0;
    left: 0;
    text-align: right;
    font-size: 0.8571rem;
    color: $alert;
  }

  &__tabpanel {
    margin-bottom: 35px;

    .mb-30 {
      margin-bottom: 7px;

      @include _576 {
        margin-bottom: 30px;
      }
    }

    .place-order__map-title {
      display: none;
    }

    input {
      font-size: 0.9rem;

      @include _576 {
        font-size: 1rem;
      }
    }

    .place-order__address-wrapper {
      margin-bottom: 0;

      .text-field__error-message {
        padding-top: 0;
        font-size: 0.7rem;

        @include _576 {
          padding-top: 5px;
          font-size: 0.8571rem;
        }
      }
    }
  }

  .MuiFormControl-root_radio {
    display: flex;

    .MuiFormGroup-root {
      flex-wrap: nowrap;
    }
  }

  &__label {
    width: 100%;
    color: $light-gray;
    font-size: 0.8rem;

    @include _576 {
      font-size: 1rem;
    }

    .Mui-checked + .MuiFormControlLabel-label {
      color: $green-dark;
    }

    .MuiFormControlLabel-label {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  #street-autocomplete {
    padding-right: 60px;
  }

  &__time-select {
    margin-bottom: 30px;

    .dropdown__list-options {
      top: auto;
      bottom: 0;
    }

    &.adress-selector {
      .dropdown__list-options {
        padding: 13px 0 70px;
      }
    }

    &.restaurant-selector {
      .dropdown__list-options {
        padding: 13px 0 35px;
      }
    }
  }

  &__restaurants {
    .dropdown__list-options {
      padding-top: 60px;
    }
  }

  &__btn__group {
    display: flex;
    flex-flow: column-reverse nowrap;
    margin-bottom: 0;
    //margin-top: auto;

    @include _576 {
      flex-flow: row nowrap;
      justify-content: flex-end;
    }
  }

  &__subheading {
    margin-top: 0;
    margin-bottom: 22px;
    //text-align: center;
    font-size: 1.143rem;
    font-weight: 400;
    color: $green-dark;
  }

  &__btn {
    padding: 10px 40px;

    &:last-child {
      margin-bottom: 20px;

      @include _576 {
        margin: 0;
      }
    }

    &:first-child {
      z-index: 0;

      @include _576 {
        margin: 0 auto 0 0;
      }

      @include _768 {
        margin: 0 30px 0 0;
      }
    }
  }
}
