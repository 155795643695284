@import '../../media';
@import '../../assets/colors';

.tag {
  background-color: $green-primary;
  color: $white;
  width: max-content;
  display: flex;
  align-items: center;
  font-size: 9px;
  font-weight: 500;
  padding: 3px 10px;
  border-radius: 1rem;
  padding-top: 4px;
  font-weight: 100;

  @include _768 {
    font-size: 11px;
  }

  &--loyalty {
    gap: 2px;
    background-color: hsl(181, 43%, 23%);
    padding: 2px;
    padding-right: 6px;

    @include _768 {
      gap: 4px;
      padding: 4px;
      padding-right: 8px;
    }
  }

  &__icon {
    height: 12px;

    @include _768 {
      height: 14px;
    }
  }

  &__text {
    display: inline-flex;
    font-size: 9px;

    @include _768 {
      font-size: 11px;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    row-gap: 5px;
    flex-wrap: wrap;
    max-width: 370px;
  }
}
