@import '../../assets/colors';
@import '../../media';

.MuiCardContent-root {
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content-container {
  padding: 20px 20px 15px !important;
}

.MuiGrid-root {
  .MuiPaper-elevation1 {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
  }
}

.product-card__content-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .product-card__price {
    font-size: 1rem;
    font-weight: 700;
    color: $green-dark;

    &__info {
      font-size: 0.8rem;
      padding-bottom: 5px;
    }
  }

  .product-card__button {
    display: flex;
    position: relative;
    align-items: center;
    padding: 6px 20px;
    z-index: 5;
    max-height: 32px;

    .product-card__button-plus {
      margin-right: 5px;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .icon-cart:before {
    font-size: 18px;
  }
}

.card-button__onclick {
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  background-color: $green-primary;
  color: white;
  overflow: hidden;
  border-radius: 30px;
  transition: 0.3s;
}

.clicked {
  width: 100%;
  height: 100%;
  padding-top: 7px;
  background-image: url('../../assets/img/success.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@include _2270 {
  .MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 15px !important;
  }
}
