@import '../../media';
@import '../../assets/colors';

.contacts__heading {
  margin-bottom: 25px;

  @include _768 {
    margin-bottom: 40px;
  }
}

.contacts__content {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 10px #e0e0e0;

  .contacts__map {
    height: 220px;
    background-color: #e7efee;

    @include _768 {
      height: 300px;
    }
  }

  .contacts__info {
    padding: 40px 20px 0;

    @include _768 {
      padding: 55px 55px 0;
    }

    .contacts__phone {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      @include _768 {
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 60px;
      }

      .contacts__phone-label {
        margin-bottom: 8px;
        font-size: 1rem;
        color: $light-gray;

        @include _768 {
          margin-right: 15px;
          margin-bottom: 2px;
          font-size: 0.8571rem;
        }
      }

      .contacts__phone-link {
        font-size: 1.571rem;
        font-weight: 700;
        text-decoration: none;
        color: black;
        transition: color 0.3s;
        text-decoration: none;

        @include _768 {
          font-size: 1.143rem;
        }

        &:hover {
          color: $green-primary;
        }
      }

      .telegram__label {
        @include _768 {
          margin-left: 40px;
        }
      }

      .link {
        background-color: #ffffff;
      }
    }

    .contacts__address-list {
      @include _768 {
        display: flex;
        flex-wrap: wrap;
      }

      .address {
        position: relative;
        padding: 2px 0 40px 35px;

        @include _768 {
          padding: 2px 0 55px 35px;
        }

        .address__icon {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 20px;

          .icon-metro {
            &:before {
              font-size: 20px;
              content: '\e938';
            }
          }
        }

        .address__title {
          margin-bottom: 10px;
          font-size: 1rem;
          color: $green-dark;
          font-weight: 700;
          text-transform: uppercase;
        }

        .address__text {
          width: 100%;
          font-size: 0.7857rem;
          line-height: 2 !important;
          color: $medium-gray;

          @include _768 {
            font-size: 0.8571rem;
          }
        }

        .address__worktime {
          position: relative;
          padding-top: 10px;
          padding-left: 25px;

          .icon-worktime {
            position: absolute;
            top: 15px;
            left: 0;

            &:before {
              content: '\e90b';
              color: #aec719;
            }
          }
        }
      }
    }
  }
}
