@import '../../media';
@import '../../assets/_colors';

.get-points-heading {
  margin-bottom: 20px;
  letter-spacing: 0.079em;
  line-height: 1.35em !important;

  @include _768 {
    letter-spacing: 0;
  }
}

.get-points-list {
  color: #000;

  .get-points-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-bottom: 65px;
  }

  .get-points-number {
    margin-right: 65px;
    width: 20px;
    font-size: 3.571rem;
    font-weight: 700;
    color: $green-primary;
    user-select: none;
  }

  .get-points-desc {
    margin: 0;
    padding-top: 10px;
    font-size: 1.143rem;
  }
}
