@import '../../media';
@import '../../assets/_colors';

.restaurant {
  background-color: $white;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.restaurant__content {
  padding: 25px;

  @include _768 {
    padding: 55px 55px 35px;
  }
}

.restaurant__contacts {
  @include _768 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .address {
    margin-bottom: 30px;
  }
}

.restaurant__address {
  @include _768 {
    width: 50%;
  }
}

.restaurant__phone {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;

  @include _768 {
    width: 40%;
  }
}

.restaurant__contacts-phone {
  display: inline-block;
}

.restaurant__map {
  height: 210px;
  margin-bottom: 40px;
  background-color: $super-light-gray;

  @include _768 {
    margin-bottom: 55px;
  }

  .delivery-areas__map {
    @include _768 {
      height: 220px;
    }
  }
}

.restaurant__text {
  font-size: 1rem;
  color: $green-dark;
  font-weight: 700;
  text-transform: uppercase;
}

.restaurant__slider {
  height: 320px;

  .CarouselItem {
    height: 100% !important;

    > * {
      height: 100% !important;
    }
  }
}

.restaurant__slider-img {
  height: 320px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.address {
  position: relative;
  padding: 2px 0 0 35px;
}

.address__icon {
  position: absolute;
  top: 0;
  left: 0;
}

.address__title {
  margin-bottom: 10px;
  font-size: 1rem;
  color: $green-dark;
  font-weight: 700;
  text-transform: uppercase;
}

.address__text {
  font-size: 0.7857rem;
  line-height: 2;
  color: $medium-gray;

  @include _768 {
    font-size: 0.8571rem;
  }
}

.icon-phone {
  &:before {
    content: '\e916';
    color: $citrus;
    font-size: 20px !important;
  }
}

.icon-metro-orange:before {
  content: '\e934';
  color: $orange;
  font-size: 20px !important;
}

.icon-metro-purple:before {
  content: '\e935';
  color: $purple;
  font-size: 20px !important;
}

.icon-metro-red:before {
  content: '\e936';
  color: $red;
  font-size: 20px !important;
}

.icon-metro-blue:before {
  content: '\e937';
  color: $blue;
  font-size: 20px !important;
}

.icon-metro-green:before {
  content: '\e938';
  color: $green;
  font-size: 20px !important;
}

a {
  text-decoration: none;
}
