.dangerous-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dangerous-button {
  color: white;
  background-color: crimson;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: bold;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
}
