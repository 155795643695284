@import '../../assets/colors';

.icon-size:before {
  margin-left: -1em;
  font-size: 27px;
  color: $green-dark;
}

.icon-path1:before {
  font-size: 27px;
  opacity: 0.3;
}

.icon-bad {
  .icon-path1:before {
    content: '\e939';
    color: #c13b06;
  }

  .icon-path2:before {
    content: '\e93a';
  }

  .icon-path3:before {
    content: '\e93b';
  }

  .icon-path4:before {
    content: '\e93c';
  }

  .icon-path5:before {
    content: '\e93d';
  }
}

.icon-fine {
  .icon-path1:before {
    content: '\e943';
    color: $green-primary;
  }

  .icon-path2:before {
    content: '\e944';
  }

  .icon-path3:before {
    content: '\e945';
  }

  .icon-path4:before {
    content: '\e946';
  }

  .icon-path5:before {
    content: '\e947';
  }
}
